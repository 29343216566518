"use strict";
import DiscovergyLineChart from './discovergyLineChart';
import DiscovergyBarChart from './discovergyBarChart';
import { Scope, Utils } from './utils';
import LiveCycleLoop, { lastDataReceivedAt } from "./liveCycleLoop";
import * as moment from 'moment';
import RLMBarChart from "./RLMBarChart";
export default class Portal {
    constructor(config) {
        this.configuration = config;
        let contextChart = new DiscovergyBarChart('#chart2', this.configuration);
        let that = this;
        contextChart.setNavigationElement(".navigationSVG svg .dial");
        contextChart.onClickElement((d) => {
            that.focusOnElement(d);
        });
        let focusChart;
        if (config.solution === "LOAD_PROFILE") {
            focusChart = new RLMBarChart('#chart1', this.configuration);
            focusChart.scope = Scope.FIFTEENMINUTE;
            focusChart.setNavigationElement(".navigationSVG svg .dial");
        }
        else {
            focusChart = new DiscovergyLineChart('#chart1', this.configuration);
            focusChart.setEnableZoom(true);
            focusChart.onZoom(function (from, to) {
                that.updateFocusChart(from, to);
            });
            focusChart.onResetZoom(function (from, to) {
                let newFrom = moment(from).startOf('day').valueOf();
                let newTo = moment(newFrom).clone().endOf('day').valueOf();
                that.focusChart.liveMode = false;
                that.updateFocusChart(newFrom, newTo);
            });
            focusChart.onLiveModeChange(function (liveMode) {
                if (this.liveMode) {
                    let to = Math.max(lastDataReceivedAt, that.configuration.upperBound);
                    let from = to - (that.focusChart.getWidth() * that.configuration.measurementInterval / 2);
                    that.updateFocusChart(from, to, function () {
                        that.startUpdatingLiveData();
                    });
                }
                else {
                    //let from = this.contextChart.activeDay ? moment(this.contextChart.activeDay).startOf('day') : moment(this.focusChart.from).clone().startOf('day');
                    let from = moment(that.focusChart.from).clone().startOf('day');
                    let to = from.clone().endOf('day');
                    that.updateFocusChart(from.valueOf(), to.valueOf(), function () {
                        that.stopUpdatingLiveData();
                    });
                }
            });
            focusChart.setNavigationElement(".navigationLiveSVG svg .dial");
        }
        focusChart.onNavigate(function (from, to) {
            that.updateFocusChart(from, to);
        });
        this.contextChart = contextChart;
        this.focusChart = focusChart;
        this.init();
        $(window).on("resize", function () {
            var chart_height = $(window).height();
            console.log('window height:' + chart_height);
            if (that.configuration.type === 'PORTAL') {
                chart_height = chart_height - 185;
            }
            if (chart_height < 300)
                chart_height = 300;
            $("#chartContainer").height(chart_height);
        });
    }
    focusOnElement(d) {
        if (d.scope == Scope.DAY) {
            this.updateFocusChart(d.from, d.to);
        }
        else {
            let parameters = new buckets.Dictionary();
            parameters.set("from", d.from + "");
            parameters.set("to", d.to + "");
            parameters.set("aggregation", Utils.getAggregation(this.contextChart.scope, this.configuration.solution));
            this.contextChart.updateChart(this.configuration.contextChartDataSource, parameters);
        }
    }
    startUpdatingLiveData() {
        let liveCycle = LiveCycleLoop.getInstance(this.configuration.flowGraphicDataSource, this.configuration.upperBound, this.configuration.measurementInterval);
        let that = this;
        let activeTimePeriod = that.focusChart.to - that.focusChart.from;
        let desiredLength = activeTimePeriod / Math.max(activeTimePeriod / (that.focusChart.getWidth() / 2), that.configuration.measurementInterval);
        this.liveDataCallBackId = liveCycle.registerFordata(function (data) {
            let dataSeries = _.filter(Utils.mapToDataSeries(data), function (s) {
                return s.dimension == ((that.configuration.measurementType === 'ELECTRICITY' || that.configuration.measurementType === 'HEATING') ? 'POWER' : 'VOLUME_FLOW');
            });
            let voltageSeries = _.filter(Utils.mapToDataSeries(data), function (s) {
                return s.dimension == 'VOLTAGE';
            });
            dataSeries = dataSeries.concat(voltageSeries);
            if (that.configuration.convertToMicrogrid) {
                dataSeries = Utils.convertPVSeriesToMicroGrid(dataSeries, that.configuration.producerKey, that.configuration.feedInTariff);
            }
            console.log("existing length:" + that.focusChart.data[0].values.length);
            console.log("existing lastTimeStamp:" + new Date(that.focusChart.data[0].values[that.focusChart.data[0].values.length - 1].x));
            let resultTimeStamps = [];
            var powerSeries = _.filter(data, function (s) {
                return s.type == 'POWER';
            });
            powerSeries.forEach(function (s) {
                s.values.forEach(function (v) {
                    resultTimeStamps.push(v.time);
                });
            });
            resultTimeStamps = _.uniq(resultTimeStamps);
            resultTimeStamps.sort();
            resultTimeStamps.forEach(function (ts) {
                console.log(new Date(ts));
            });
            let result = that.focusChart.data;
            var dataUpdated = false;
            result.forEach(function (s) {
                let matchingDataSeries = _.find(dataSeries, function (r) {
                    return s.seriesType == r.seriesType;
                });
                let lastValueTime = s.values[s.values.length - 1].x;
                if (matchingDataSeries) {
                    let values = _.filter(matchingDataSeries.values, function (value) {
                        return value.x > lastValueTime;
                    });
                    if (values && values.length > 0) {
                        dataUpdated = true;
                        values.forEach(function (val) {
                            s.values.push(val);
                        });
                    }
                }
                else if (that.configuration.solution == 'DISAGGREGATION') {
                    let values = _.filter(resultTimeStamps, function (t) {
                        return t > lastValueTime;
                    });
                    values.sort();
                    if (values && values.length > 0) {
                        dataUpdated = true;
                        values.forEach(function (t) {
                            s.values.push({ x: t, y: 0, reading: 0, reactiveReading: 0, selfConsumptionReward: 0, cost: 0 });
                        });
                    }
                }
            });
            if (dataUpdated) {
                console.log("length:" + result[0].values.length);
                console.log("lastTimeStamp" + new Date(result[0].values[result[0].values.length - 1].x));
                result.forEach(function (s) {
                    if (s.values && s.values.length > desiredLength) {
                        var excess = s.values.length - desiredLength;
                        s.values.splice(0, excess);
                    }
                });
                console.log("length after splice:" + result[0].values.length);
                that.focusChart.from = result[0].values[0].x;
                that.focusChart.to = Math.max(that.focusChart.to, result[0].values[result[0].values.length - 1].x);
                that.focusChart.render(result);
            }
        });
        console.info('registed for livecycle data:' + this.liveDataCallBackId);
    }
    stopUpdatingLiveData() {
        let liveCycle = LiveCycleLoop.getInstance(this.configuration.flowGraphicDataSource, this.focusChart.to, this.configuration.measurementInterval);
        liveCycle.unregister(this.liveDataCallBackId);
        console.info('deregistered for livecycle data:' + this.liveDataCallBackId);
        this.liveDataCallBackId = '';
    }
    updateFocusChart(from, to, callBack) {
        let interval = Math.round((to - from) / (this.focusChart.getWidth() / 2));
        let parameters = new buckets.Dictionary();
        parameters.set("from", from);
        parameters.set("to", to);
        parameters.set("interval", Math.max(interval, this.configuration.measurementInterval));
        if (this.configuration.solution === "LOAD_PROFILE") {
            parameters.set("aggregation", 'FIFTEEN_MINUTELY');
        }
        if (to < lastDataReceivedAt && this.focusChart.liveMode) {
            this.focusChart.liveMode = false;
            this.stopUpdatingLiveData();
        }
        let that = this;
        if (this.configuration.solution === 'MARKET_PRICES' && moment(from).isAfter(moment().endOf('day'))) {
            let data = new Array();
            let values = new Array();
            var begin = from;
            while (begin < to) {
                values.push({
                    time: begin,
                    value: 0,
                    reading: 0,
                    reactiveReading: 0,
                    cost: 0,
                    selfConsumptionReward: 0
                });
                begin = begin + 3600000;
            }
            data.push({
                meterSerialNumber: '',
                type: 'POWER',
                values: values,
                seriesKey: 'Bezug',
                color: '#bdc3c7',
                seriesType: 'CONSUMPTION',
                fillArea: true,
                producersCost: null,
                producersCostPerKWh: null,
                costKey: 'Kosten',
                cost: 0,
                total: 0,
                sortIndex: 0,
                extras: null
            });
            this.focusChart.from = from;
            this.focusChart.to = to;
            this.focusChart.render(Utils.mapToDataSeries(data));
            that.contextChart.highlightBars(from, to);
        }
        else {
            that.focusChart.updateChart(this.focusChart.dataSource, parameters, function (result) {
                that.contextChart.activeDay = moment(from).clone().startOf('day');
                that.contextChart.highlightBars(from, to);
                if (callBack) {
                    callBack();
                }
                if (!that.focusChart.liveMode && to >= Math.max(lastDataReceivedAt, that.configuration.upperBound) && to - from < 86000000 && that.configuration.solution !== "LOAD_PROFILE") {
                    that.focusChart.liveMode = true;
                    that.startUpdatingLiveData();
                }
            });
        }
    }
    init() {
        var from;
        if (this.focusChart.from) {
            from = moment(this.focusChart.from).startOf('month');
        }
        else {
            let upperBound = this.configuration.solution === "LOAD_PROFILE" ? this.configuration.lastLoadMeasurementTime : this.configuration.upperBound;
            from = moment(upperBound).startOf('month');
        }
        let to = moment(from).clone().add(1, 'months').startOf('month');
        let parameters = new buckets.Dictionary();
        parameters.set("from", from.valueOf());
        parameters.set("to", to.valueOf());
        parameters.set("aggregation", Utils.getAggregation(Scope.DAY, this.configuration.solution));
        let that = this;
        this.contextChart.updateChart(this.contextChart.dataSource, parameters, function (result) {
            if (result.length > 0 && result[0].values.length > 0) {
                let allValues = new Array();
                result.forEach(function (s) {
                    s.values.forEach(function (v) {
                        allValues.push(v);
                    });
                });
                var from;
                var to;
                if (that.focusChart.liveMode) {
                    to = Math.max(lastDataReceivedAt, that.configuration.upperBound);
                    from = to - (that.focusChart.getWidth() * that.configuration.measurementInterval / 2);
                }
                else {
                    if (that.focusChart.from) {
                        from = that.focusChart.from;
                    }
                    else if (typeof from == 'undefined') {
                        var lastMeasurementAvailableAt = that.configuration.upperBound;
                        if (that.configuration.solution === "LOAD_PROFILE") {
                            lastMeasurementAvailableAt = that.configuration.lastLoadMeasurementTime;
                        }
                        if (lastMeasurementAvailableAt > 0) {
                            from = moment(lastMeasurementAvailableAt).startOf('day').valueOf();
                        }
                    }
                    to = that.focusChart.to ? that.focusChart.to : from + 86400000;
                }
                that.updateFocusChart(from, to, function () {
                    if (that.focusChart.liveMode) {
                        that.startUpdatingLiveData();
                    }
                });
            }
        });
    }
    destroy() {
        if (this.liveDataCallBackId) {
            this.stopUpdatingLiveData();
        }
        this.focusChart.destroy();
        this.contextChart.destroy();
    }
    updateDataSources(configuration) {
        this.configuration = configuration;
        this.contextChart.scope = Scope.DAY;
        this.focusChart.updateConfiguration(configuration);
        this.contextChart.updateConfiguration(configuration);
        this.focusChart.updateDataSource(configuration.focusChartDataSource);
        this.contextChart.updateDataSource(configuration.contextChartDataSource);
        //this.contextChart.onInitialize();
        this.init();
    }
    updateActiveMeasurementType(m) {
        this.contextChart.scope = Scope.DAY;
        this.focusChart.updateActiveMeasurementType(m);
        this.contextChart.updateActiveMeasurementType(m);
        this.init();
    }
    setSeriesVisibility(key, visible) {
        this.focusChart.setSeriesVisibility(key, visible);
        this.contextChart.setSeriesVisibility(key, visible);
    }
    setOnUpdateCallback(callback) {
        this.focusChart.setOnUpdateCallback(callback);
        this.contextChart.setOnUpdateCallback(callback);
    }
    getDeviceIcons() {
        var series = new buckets.Set(function (t) {
            return t.seriesType;
        });
        this.addDeviceIcons(this.focusChart.data, series);
        this.addDeviceIcons(this.contextChart.data, series);
        var s = _.sortBy(series.toArray(), function (s) {
            return s.order;
        });
        return s;
    }
    addDeviceIcons(dataSeries, icons) {
        if (dataSeries) {
            dataSeries.forEach((s) => {
                if (s.seriesType !== null && s.seriesType.indexOf('CONSUMPTION_DEVICE_') !== -1) {
                    icons.add({
                        'seriesType': s.seriesType,
                        'key': s.key,
                        'color': tinycolor(s.color).setAlpha(1).toRgbString(),
                        'iconClass': Utils.getDeviceIconClass(s.seriesType),
                        'order': s.sortIndex,
                        'power': -1
                    });
                }
            });
        }
    }
    render(selectedDisaggregationAlgorithm) {
        this.contextChart.selectedDisaggregationAlgorithm = selectedDisaggregationAlgorithm;
        this.focusChart.selectedDisaggregationAlgorithm = selectedDisaggregationAlgorithm;
        this.contextChart.render(this.contextChart.data);
        this.focusChart.render(this.focusChart.data);
    }
}
d3.selection.prototype.moveToFront = function () {
    return this.each(function (e) {
        let that = this;
        if (that.parentNode != null)
            that.parentNode.appendChild(that);
    });
};
