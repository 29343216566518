"use strict";
import { Chart, Dial } from './chart';
import { Utils } from './utils';
import * as moment from 'moment';
import { Solution } from './configuration';
import LiveCycleLoop from "./liveCycleLoop";
export default class DiscovergyLineChart extends Chart {
    constructor(chartElement, configuration) {
        super(chartElement, configuration);
        this.liveMode = false;
        this.weatherDataCache = new buckets.Dictionary();
        this.wrapCssClass = '.nv-linesWrap';
        this.updateActiveMeasurementType(this.activeMeasurementType);
        this.dataSource = configuration.focusChartDataSource;
        let that = this;
        let chart = nv.models.extendedLineChart();
        chart.xScale(d3.time.scale()).x(function (d) {
            return new Date(d.x);
        });
        chart.forceY([0]);
        chart.useInteractiveGuideline(true) //We want nice looking tooltips and a guideline!
            .showLegend(true) //Show the legend, allowing users to turn on/off line series.
            .showYAxis(true) //Show the y-axis
            .showXAxis(true) //Show the x-axis
        ;
        chart.xAxis //Chart x-axis settings
            .showMaxMin(false).orient('bottom').tickPadding(5)
            .tickFormat(this.localeFormat);
        chart.yAxis.axisLabel(function () {
            if (that.seriesAbsoluteMax >= 10000 && (that.activeMeasurementType == 'ELECTRICITY' || that.activeMeasurementType === 'HEATING')) {
                var prefix = d3.formatPrefix(this.seriesAbsoluteMax);
                return prefix.symbol + that.baseUnit;
            }
            return that.baseUnit;
        });
        chart.yAxis.tickFormat(function (d) {
            if (that.seriesAbsoluteMax >= 10000 && (that.activeMeasurementType == 'ELECTRICITY' || that.activeMeasurementType === 'HEATING')) {
                var prefix = d3.formatPrefix(that.seriesAbsoluteMax);
                return Utils.formatDecimal(prefix.scale(d * that.conversionFactor));
            }
            return Utils.formatDecimal(d * that.conversionFactor);
        });
        chart.yAxis2.axisLabel("V");
        chart.yAxis2.tickFormat(function (d) {
            return d * Math.pow(10, -3);
        });
        chart.interactiveLayer.tooltip.contentGenerator(function (data) {
            //console.log('index:' + data.index);
            var toolTipEntries = [[], [], []];
            if (that.configuration.solution === 'MICROGRID_CONSUMER') {
                let microgridData = Utils.getMicrogridData(that.data, that.configuration.tariff, that.configuration.feedInTariff, that.configuration.originalSolution == Solution.PRODUCTION_CONSUMPTION, data.index);
                toolTipEntries[0].push('<strong style=\'color:' + microgridData.producerColor + '\'>' + Utils.getTranslatedMicrogridKey(microgridData.producerKey) + ': </strong>');
                toolTipEntries[0].push('<strong>' + that.getLocalizedSemanticValue(microgridData.producerValue / that.conversionFactor) + ' (' + microgridData.producerPercentage + '%)' + '</strong>');
                toolTipEntries[1].push('<strong style=\'color:' + microgridData.gridColor + '\'>' + Utils.getTranslatedMicrogridKey(microgridData.gridKey) + ': </strong>');
                toolTipEntries[1].push('<strong>' + that.getLocalizedSemanticValue(microgridData.gridValue / that.conversionFactor) + ' (' + microgridData.gridPercentage + '%)' + '</strong>');
                if ((that.configuration.originalSolution == Solution.MICROGRID_CONSUMER || that.configuration.feedInTariff) && that.configuration.tariff) {
                    toolTipEntries[2].push('<strong>' + i18next.t('price') + ': </strong>');
                    let priceText = '<strong style=\'color:' + microgridData.netPriceColor + '\' > ' + Utils.formatRounded(microgridData.netPrice, 'ct/kWh', that.locale) + '</strong > ';
                    if (that.configuration.originalSolution == Solution.MICROGRID_CONSUMER && microgridData.producerValue > 0) {
                        priceText = priceText + '<br> <span style=\'color:' + microgridData.netPriceColor + '\' >&nbsp;' + i18next.t('includingEEGAndVAT') + '</span>';
                    }
                    toolTipEntries[2].push(priceText);
                }
            }
            else {
                if (that.configuration.solution === 'PRODUCTION_CONSUMPTION') {
                    let selfConsumptionSeries = _.find(data.series, function (s) {
                        return s.seriesType === 'OWN_CONSUMPTION';
                    });
                    let feedIndex = _.findIndex(data.series, function (s) {
                        return s.seriesType === 'FEED';
                    });
                    let consumptionIndex = _.findIndex(data.series, function (s) {
                        return s.seriesType === 'CONSUMPTION';
                    });
                    if (selfConsumptionSeries) {
                        let selfConsumption = selfConsumptionSeries.value;
                        if (feedIndex != -1) {
                            data.series[feedIndex].value -= selfConsumption;
                        }
                        if (consumptionIndex != -1) {
                            data.series[consumptionIndex].value -= selfConsumption;
                        }
                    }
                }
                if (that.configuration.solution === 'DISAGGREGATION') {
                    data.series.reverse();
                }
                toolTipEntries = [];
                data.series.forEach(function (d, i) {
                    toolTipEntries.push([]);
                    if (that.configuration.solution === 'DISAGGREGATION') {
                        let iconClass = that.configuration.solution == 'DISAGGREGATION' ? 'tooltipIcon ' + Utils.getDeviceIconClass(d.seriesType) : '';
                        toolTipEntries[i].push('<i style="color:#FFFFFF;background-color:' + d.color + '" class="' + iconClass + '"></i>  ');
                    }
                    else {
                        toolTipEntries[i].push('<strong style=\'color:' + d.color + '\'>' + d.key + ': </strong>');
                    }
                    let unitMap = Utils.unitMap.get(d.dimension);
                    let value = that.configuration.solution === 'THREE_PHASE' ? d.value : Math.abs(d.value);
                    if (d.dimension === 'VOLTAGE') {
                        toolTipEntries[i].push('<strong>' + Utils.formatRounded(value * that.conversionFactor, 'V') + '</strong>');
                    }
                    else {
                        toolTipEntries[i].push('<strong>' + that.getLocalizedSemanticValue(value) + '</strong>');
                    }
                });
            }
            var table = d3.select(document.createElement("table"));
            //TODO remove extra borders in the tooltip
            table.attr('class', 'table table-condensed').style('border-bottom', 'solid 1px #ddd');
            var thead = table.append('thead');
            var tbody = table.append('tbody');
            thead.append("tr").append("td").attr("colspan", 4).append("strong").classed("x-value", true).html(that.locale.timeFormat("%c")(new Date(data.series[0].data.x))
            //moment(data.series[0].data.x).format("D.\u202FMMMM YYYY, HH:mm:ss")
            );
            var rows = tbody.selectAll('tr')
                .data(toolTipEntries)
                .enter()
                .append('tr');
            var cells = rows.selectAll('td').data(function (row) {
                return row;
            })
                .enter()
                .append('td').style('border-right', function (d, i) {
                return '1px solid #dddd';
            }).style('padding', function (d, i) {
                return that.configuration.solution === 'DISAGGREGATION' ? (i % 2 == 0 ? '10px' : '15px 10px') : '5px';
            })
                .style('text-align', function (d, i) {
                return that.configuration.solution === 'DISAGGREGATION' ? (i % 2 == 0 ? 'center' : 'right') : 'left';
            })
                .html(function (v) {
                return v;
            });
            if (data.series[0].data.isComputedValue) {
                return `<div> ${table.node().outerHTML} <span style="color:#A9A9A9;padding-left: 5px;padding-top: 5px;font-size: x-small"> ${i18next.t("substituteValue")} </span> </div>`;
            }
            return table.node().outerHTML;
        });
        this.chart = chart;
        super.onInit();
    }
    onLiveModeChange(callback) {
        this.liveModeListener = callback;
    }
    onNavigateChart(dial) {
        switch (dial) {
            case Dial.UP:
                this.transformFocusChart(0.5, 0.4, -0.4, 1);
                break;
            case Dial.DOWN:
                this.transformFocusChart(0.5, -0.4, 0.4, 3);
                break;
            case Dial.LEFT:
                this.transformFocusChart(0.5, -0.2, -0.2, 4);
                break;
            case Dial.RIGHT:
                this.transformFocusChart(0.5, 0.2, 0.2, 2);
                break;
            case Dial.CENTER:
                this.liveMode = !this.liveMode;
                this.liveModeListener(this.liveMode);
                //TODO
                break;
        }
    }
    transformFocusChart(anchor, leftFactor, rightFactor, dialButtonIndex) {
        var xDomain = this.chart.xAxis.domain();
        var currentFrom = xDomain[0];
        var currentTo = xDomain[xDomain.length - 1];
        if (currentFrom instanceof Date) {
            currentFrom = currentFrom.getTime();
        }
        if (currentTo instanceof Date) {
            currentTo = currentTo.getTime();
        }
        var difference1 = (currentTo - currentFrom) * anchor;
        var difference2 = (currentTo - currentFrom) * (1 - anchor);
        if (dialButtonIndex === 1 && ((currentTo + difference2 * rightFactor) - (currentFrom + difference1 * leftFactor)) < 60000)
            return;
        var limit = 604800000;
        //  if (showThreePhases)
        //  limit = 172800000;
        if (dialButtonIndex === 3 && ((currentTo + difference2 * rightFactor) - (currentFrom + difference1 * leftFactor)) > limit)
            return;
        let from = Math.trunc(currentFrom + difference1 * leftFactor);
        let to = Math.trunc(currentTo + difference2 * rightFactor);
        if (from > LiveCycleLoop.getLastMeasurementTimeStamp()) {
            humane.log("Nach diesem Zeitraum sind keine Daten verfügbar", { timeout: 3000, clickToClose: true, addnCls: 'humane-error' });
            return;
        }
        if (to < this.configuration.lowerBound) {
            humane.log("Vor diesem Zeitraum sind keine Daten verfügbar", { timeout: 3000, clickToClose: true, addnCls: 'humane-error' });
            return;
        }
        console.log('transform ' + new Date(from) + "-" + new Date(to));
        this.onNavigateCallback(from, to);
    }
    updateScopeLabel() {
        d3.select(this.wrapCssClass + " .scopeLabel").remove();
        d3.select(this.wrapCssClass).append('text').attr("text-anchor", "start").attr("class", "scopeLabel").attr('x', 38).attr('y', 24);
        if ((this.chart.xAxis.domain()[1].getTime() - this.chart.xAxis.domain()[0].getTime()) < 2)
            return;
        // This is done to avoid counting midnight as the next day
        var startTime = new Date(this.chart.xAxis.domain()[0].getTime() + 1);
        var endTime = new Date(this.chart.xAxis.domain()[1].getTime() - 1);
        var startDay = this.locale.timeFormat("%e")(startTime);
        var endDay = this.locale.timeFormat("%e")(endTime);
        var startMonth = this.locale.timeFormat("%B")(startTime);
        var endMonth = this.locale.timeFormat("%B")(endTime);
        var startYear = this.locale.timeFormat("%Y")(startTime);
        var endYear = this.locale.timeFormat("%Y")(endTime);
        if (startDay == endDay && startMonth == endMonth && startYear == endYear) {
            var scopeText = this.locale.timeFormat("%x")(startTime) + "";
        }
        else if (startMonth == endMonth && startYear == endYear) {
            var scopeText = startDay + ". " + " – " + endDay + ". " + " " + startMonth + " " + startYear;
        }
        else if (startYear == endYear) {
            var scopeText = startDay + ". " + startMonth + " – " + endDay + ". " + endMonth + " " + startYear;
        }
        else {
            var scopeText = startDay + ". " + startMonth + " " + startYear + " – " + endDay + ". " + endMonth + " " + endYear;
        }
        d3.select(this.wrapCssClass + " .scopeLabel").text(scopeText);
    }
    onBeforeRender() {
        if ((this.to - this.from) >= 82800000) {
            this.chart.forceX([this.from, this.to]);
            this.chart.lines2.forceX([this.from, this.to]);
        }
        else {
            let from = this.data[0].values[0].x;
            let to = this.data[0].values[this.data[0].values.length - 1].x;
            this.chart.forceX([from, to]);
            this.chart.lines2.forceX([from, to]);
        }
        if (this.configuration.solution === 'DISAGGREGATION') {
            this.chart.showLegend(false);
            this.chart.noData(i18next.t('noDataForThisDay'));
        }
        else {
            this.chart.showLegend(true);
            this.chart.noData(i18next.t('noData'));
        }
    }
    add_line(level, color, label, id) {
        let yScale = this.chart.yScale();
        const yLevel = yScale(level);
        const width = this.getWidth();
        d3.select(this.chartElement + ' .nv-linesWrap.nvd3-svg').append("line").attr("class", "line marker").attr("x1", 0).attr("x2", width).attr("y1", yLevel).attr("y2", yLevel)
            .style("stroke", color).style("stroke-width", "2");
        d3.select(this.chartElement + ' .nv-linesWrap.nvd3-svg').append("text").attr("class", "line-label marker").attr("id", id).attr("x", width + 4).attr("y", yLevel + 4)
            .attr("text-anchor", "start").text(label).append("svg:title").text(label + ": " + this.getLocalizedSemanticValue(level));
    }
    getAbsValueAccessor() {
        let that = this;
        return function (d, i) {
            return Math.abs(d.y);
        };
    }
    onInitialize() {
        let from = this.chart.xAxis.domain()[0];
        let to = this.chart.xAxis.domain()[1];
        if (to.getTime() - from.getTime() < 86400000) {
            let newTo = moment(from).clone().endOf('day');
        }
    }
    onAfterRender() {
        if (this.seriesAbsoluteMax >= 10000 && (this.activeMeasurementType == 'ELECTRICITY' || this.activeMeasurementType === 'HEATING')) {
            var prefix = d3.formatPrefix(this.seriesAbsoluteMax);
            this.chart.yAxis.axisLabel(prefix.symbol + this.baseUnit);
        }
        else {
            this.chart.yAxis.axisLabel(this.baseUnit);
        }
        this.chart.yAxis2.axisLabel('V');
        d3.select('.nv-focus .nv-y').call(this.chart.yAxis);
        if (this.data.filter(function (d) {
            return !d.disabled && d.yAxis == 2;
        }).length == 0) {
            d3.select('.nv-y2').style("display", "none");
        }
        else {
            d3.select('.nv-y2').style("display", "block");
        }
        d3.select('.nv-focus .nv-y2').call(this.chart.yAxis2);
        this.adjustLabelPosition();
        $('.nv-focus .nv-wrap.nv-line').after($('.zoom').detach());
        $('.nv-focus .downloadIcon').after($('.zoomSummaryLayer').detach());
        if (this.liveMode) {
            var coloredPart = $(".nvd3 .navigationButton.center");
            coloredPart.attr("class", "navigationButton center following");
        }
        let that = this;
        d3.selectAll('.customLegend').remove();
        if (this.configuration.solution == 'MICROGRID_CONSUMER') {
            let gradient = that.buildGradient();
            d3.selectAll('.nv-focus .nv-groups').selectAll('.nv-area').style("fill", gradient);
            d3.selectAll('.nv-focus .nv-groups').selectAll('.nv-line').style("stroke", gradient);
            //TODO change line color to gradient
            this.createLegendGradient();
            let label1 = _.find(this.data, function (d) {
                return d.seriesType === 'CONSUMPTION_FROM_PRODUCER';
            }).key;
            label1 = Utils.getTranslatedMicrogridKey(label1);
            let label2 = i18next.t('fromGrid');
            this.showMicroGridLegend(label1, label2);
        }
        else if (this.configuration.solution == 'MARKET_PRICES') {
            if (!this.marketPrices) {
                this.marketPrices = _.flatten(Utils.eexPrices);
            }
            const marketPricesFrom = this.marketPrices[0].time;
            const marketPricesTo = this.marketPrices[this.marketPrices.length - 1].time;
            const marketPricesAvailable = marketPricesFrom <= this.from && marketPricesTo >= Math.min(this.to, moment().startOf('hour').valueOf());
            if (marketPricesAvailable) {
                this.addLivePriceIndicator();
            }
            else {
                Utils.getJSONAsync("/json/PortalJSON.getMarketPrices?from=" + moment(this.from).startOf('month').valueOf() + "&to=" + moment(this.to).add(1, 'months').startOf('month').valueOf(), (dataIn) => {
                    const prices = dataIn.map((price) => ({
                        time: price.time,
                        hour: moment(price.time).hour(),
                        value: price.pricePerKWh,
                    }));
                    this.marketPrices = prices;
                    this.addLivePriceIndicator();
                });
            }
        }
        else {
            d3.selectAll('.nv-focus .nv-groups').selectAll('.nv-area').style("fill", "inherit");
            d3.selectAll(".livePriceIndicator").remove();
        }
        d3.selectAll('.marker').remove();
        let accessor = this.getAbsValueAccessor();
        let series = _.filter(that.data, function (d) {
            return (d.seriesType == "CONSUMPTION" || d.seriesType == "CONSUMPTION_TARIFF2" || d.seriesType == "CONSUMPTION_COLLECTIVE" || d.seriesType == "PRODUCTION"
                || d.seriesType.indexOf('CONSUMPTION') > -1);
        });
        var consumptionValues = Utils.filterData(series, this.activeMeasurementType)[0].values;
        if (that.configuration.solution === 'TWO_TARIFF') {
            consumptionValues = consumptionValues.concat(series[1].values);
        }
        let timeThreshold = moment(that.configuration.upperBound).isSame(moment(), 'day') ? moment().valueOf() : that.configuration.upperBound;
        let filteredValues = _.filter(consumptionValues, (value) => value.x <= timeThreshold);
        let max = d3.max(filteredValues, accessor);
        let mean = d3.mean(filteredValues, accessor);
        let min = d3.min(filteredValues, accessor);
        if (that.configuration.solution !== 'THREE_PHASE' && that.configuration.solution !== 'DISAGGREGATION') {
            if (min !== undefined)
                this.add_line(min, that.configuration.colors.get("min_line_color"), "min", "min");
            if (max)
                this.add_line(max, that.configuration.colors.get("max_line_color"), "max", "max");
            if (mean)
                this.add_line(mean, that.configuration.colors.get("mean_line_color"), "∅", "mean");
        }
        d3.selectAll(this.chartElement + " .weatherData").remove();
        this.addWeatherData();
    }
    getLocalizedSemanticValue(value, precision) {
        var prefix = d3.formatPrefix(this.seriesAbsoluteMax);
        if (this.seriesAbsoluteMax > 10000 && (this.activeMeasurementType == 'ELECTRICITY' || this.activeMeasurementType === 'HEATING')) {
            let scaledValue = prefix.scale(value * this.conversionFactor);
            return this.locale.numberFormat(",." + precision + 'f')(precision ? scaledValue : Utils.getRounded(scaledValue)) + ' ' + prefix.symbol + this.baseUnit;
        }
        let scaledValue = value * this.conversionFactor;
        return this.locale.numberFormat(",." + precision + 'f')(precision ? scaledValue : Utils.getRounded(scaledValue)) + ' ' + this.baseUnit;
    }
    updateActiveMeasurementType(m) {
        this.activeMeasurementType = m;
        let unitMap = Utils.unitMap.get((m === 'ELECTRICITY' || m === 'HEATING') ? 'POWER' : 'VOLUME_FLOW');
        this.baseUnit = unitMap.unit;
        this.conversionFactor = unitMap.factor;
    }
    preprocess(data) {
        Utils.decompress(data);
        data.forEach(function (s) {
            s.color = tinycolor(s.color).setAlpha(0.85).toRgbString();
        });
    }
    buildGradient() {
        let gradients = [];
        const gradientColors = Utils.MICROGRID_GRADIENT_COLORS;
        const transitionPoints = this.getTransitionPoints();
        let producerShare = transitionPoints[0].y;
        const threshold = 10;
        const gradientBinWidth = Math.ceil(100 / gradientColors.length);
        let xScale = this.chart.xScale();
        const totalWidth = $(window).width();
        gradients.push({
            offset: (xScale(transitionPoints[0].x) / totalWidth) * 100,
            stop_color: gradientColors[(transitionPoints[0].y / gradientBinWidth) | 0]
        });
        for (let i = 1; i < transitionPoints.length; i++) {
            if (producerShare == 0 || transitionPoints[i].y == 0 || transitionPoints[i].distanceFromOldTransition > threshold
                || Math.abs(transitionPoints[i].y - producerShare) > gradientBinWidth) {
                if (transitionPoints[i].distanceFromOldTransition >= threshold) {
                    gradients.push({
                        offset: (xScale(transitionPoints[i].x) / totalWidth) * 100,
                        stop_color: gradientColors[(transitionPoints[i - 1].y / gradientBinWidth) | 0]
                    });
                }
                gradients.push({
                    offset: (xScale(transitionPoints[i].x) / totalWidth) * 100,
                    stop_color: gradientColors[(transitionPoints[i].y / gradientBinWidth) | 0]
                });
                producerShare = transitionPoints[i].y;
            }
        }
        this.addGradient(gradients);
        return "url(#multiColorAreaGradient)";
    }
    getTransitionPoints() {
        let transitionPoints = [];
        let consumptionSeries = this.data[0];
        let producerSeries = this.data[1];
        let lastTransitionPointProducerShare = -1;
        let count = 0;
        for (let i = 0; i < consumptionSeries.values.length; i++) {
            const consumption = consumptionSeries.values[i].y;
            if (consumption == 0) {
                continue;
            }
            let producerShare = Math.round((producerSeries.values[i].y / consumption) * 100);
            count++;
            if (producerShare != lastTransitionPointProducerShare) {
                lastTransitionPointProducerShare = producerShare;
                transitionPoints.push({
                    x: producerSeries.values[i].x,
                    y: producerShare,
                    distanceFromOldTransition: count,
                });
                count = 0;
            }
        }
        console.log('transition points:', transitionPoints);
        return transitionPoints;
    }
    addGradient(gradients) {
        this.createMultiColorGradients();
        gradients = gradients.filter(function (gradient) {
            return gradient.stop_color != null;
        });
        d3.select("#multiColorAreaGradient").selectAll('stop').remove();
        d3.select("#multiColorAreaGradient").selectAll('stop').data(gradients).enter().append("stop").attr("offset", function (d) {
            return (d.offset).toFixed(2) + '%';
        }).attr("stop-color", function (d) {
            return tinycolor(d.stop_color).setAlpha(0.9).toRgbString();
        });
        d3.select("#multiColorLineGradient").selectAll('stop').remove();
        d3.select("#multiColorLineGradient").selectAll('stop').data(gradients).enter().append("stop").attr("offset", function (d) {
            return (d.offset).toFixed(2) + '%';
        }).attr("stop-color", function (d) {
            return d.stop_color;
        });
    }
    createMultiColorGradients() {
        if (d3.selectAll('#multiColorAreaGradient')[0].length == 0) {
            var defs = d3.select(this.chartElement).append('defs').attr('class', 'gradients');
            defs.append('svg:linearGradient').attr('id', 'multiColorAreaGradient').attr('gradientUnits', 'userSpaceOnUse').attr('x1', '0%').attr('y1', '0%').attr('x2', '100%').attr('y2', '0%');
            defs.append('svg:linearGradient').attr('id', 'multiColorLineGradient').attr('gradientUnits', 'userSpaceOnUse').attr('x1', '0%').attr('y1', '0%').attr('x2', '100%').attr('y2', '0%');
        }
    }
    createLegendGradient() {
        var stopInterval = 100 / (Utils.MICROGRID_GRADIENT_COLORS.length - 1);
        var gradient = d3.select(this.chartElement + ' .gradients');
        gradient.append("svg:linearGradient").attr("id", "legendGradient").attr("x1", "0%").attr("y1", "0%").attr("x2", "100%").attr("y2", "0%");
        for (var j = 0, i = Utils.MICROGRID_GRADIENT_COLORS.length - 1; i >= 0; i--, j++) {
            d3.select('#legendGradient').append('stop').attr('offset', (j * stopInterval) + '%').attr('stop-color', Utils.MICROGRID_GRADIENT_COLORS[i]);
        }
    }
    showMicroGridLegend(label1, label2) {
        var legendWrap = d3.select(this.chartElement + ' .nvd3.nv-legend g');
        legendWrap.selectAll('.nv-series').remove();
        let g = legendWrap.append('g').attr('class', 'customLegend');
        g.append("rect").attr("x", 0).attr("y", 0).attr("width", 150).attr("height", 20).style('fill', 'url(#legendGradient)');
        g.append("text").text(label1).attr('x', 5).attr('y', 14).attr('fill', 'black').style('font-weight', 'bold');
        g.append("text").text(label2).attr('x', 118).attr('y', 14).attr('fill', 'black').style('font-weight', 'bold');
        legendWrap.attr('transform', 'translate(' + (this.getWidth() - 150) + ', 0)');
    }
    //TODO find a better way
    getWidth() {
        let width = $(window).width() - 100 + '';
        let w = $('.nv-lineChart .nv-background rect').attr('width');
        if (w != undefined) {
            width = w;
        }
        return parseInt(width);
    }
    addLivePriceIndicator() {
        let that = this;
        let xScale = that.chart.xScale();
        let yScale = that.chart.yScale();
        d3.selectAll('.d3-tip').remove();
        d3.selectAll(".livePriceIndicator").remove();
        d3.select("#multiColorAreaGradient").selectAll('stop').remove();
        d3.select("#multiColorLineGradient").selectAll('stop').remove();
        let selectedLivePrices = [];
        const consumptionColor = this.configuration.colors.get('consumption') ? this.configuration.colors.get('consumption') : '#008739';
        const start = moment(this.from).startOf('hour');
        const end = moment(this.to).startOf('hours');
        for (let time = start; time.isBefore(end); time.add(1, "hours")) {
            const marketPrice = this.marketPrices.find(price => price.time === time.valueOf());
            if (marketPrice) {
                selectedLivePrices.push(marketPrice);
            }
            else if (selectedLivePrices.length > 0) {
                // covers the partial fulfillment
                selectedLivePrices.push({
                    time: time.valueOf(),
                    hour: time.hour(),
                    color: consumptionColor,
                    lineColor: consumptionColor
                });
            }
        }
        if (!selectedLivePrices || selectedLivePrices.length == 0) {
            d3.selectAll('.nv-focus .nv-groups').selectAll('.nv-area').style("fill", consumptionColor);
            return;
        }
        this.createMultiColorGradients();
        const livePriceIndicator = d3.select('.nv-focus .nv-linesWrap .zoom').append('g').attr('class', 'livePriceIndicator');
        const tips = d3.tip().attr("class", "d3-tip n").html(function (d) {
            let html = "<strong>Preis: </strong>";
            html += d.value + " ct/kWh";
            return html;
        });
        const startTime = that.chart.xAxis.domain()[0].getTime();
        const endTime = that.chart.xAxis.domain()[1].getTime();
        livePriceIndicator.selectAll(".shade").data(Utils.fillColorValues(selectedLivePrices)).enter().insert('rect', 'g.nv-linesWrap').attr('class', 'shade').style('stroke', 'black').attr('width', (d, i) => {
            if (!d.value) {
                return 0;
            }
            return this.calculateWidth(i, startTime, selectedLivePrices, endTime, xScale);
        }).attr('x', function (d, i) {
            const xStartTime = i === 0 ? startTime : moment(startTime).add('hours', i).startOf('hour').valueOf();
            const x = xScale(xStartTime) + 2;
            return x;
        }).attr("y", yScale(that.chart.yAxis.domain()[1])).attr("height", d3.select('.nv-focus .nv-linesWrap2').node().getBoundingClientRect().height).style('fill', function (d, i) {
            return d.color;
        }).style('fill-opacity', 0.44).on("mouseover", tips.show).on("mouseout", tips.hide);
        d3.selectAll(".shade").call(tips);
        const gradients = [];
        const totalWidth = $(window).width();
        for (let i = 0; i < selectedLivePrices.length; i++) {
            const width = this.calculateWidth(i, startTime, selectedLivePrices, endTime, xScale);
            const offset = i === (selectedLivePrices.length - 1) ? 100 : ((i == 0 ? 0 : gradients[gradients.length - 1].offset) + ((width / totalWidth) * 100));
            const previousOffset = gradients.length === 0 ? 0 : gradients[gradients.length - 1].offset;
            gradients.push({
                offset: previousOffset,
                stop_color: selectedLivePrices[i].color,
                stop_colorLine: selectedLivePrices[i].lineColor
            });
            gradients.push({
                offset: offset,
                stop_color: selectedLivePrices[i].color,
                stop_colorLine: selectedLivePrices[i].lineColor
            });
        }
        d3.select("#multiColorAreaGradient").selectAll('stop').data(gradients).enter().append("stop").attr("offset", function (d, i) {
            return (d.offset).toFixed(2) + '%';
        }).attr("stop-color", function (d) {
            return d.stop_color;
        });
        d3.select("#multiColorLineGradient").selectAll('stop').data(gradients).enter().append("stop").attr("offset", function (d, i) {
            return (d.offset).toFixed(2) + '%';
        }).attr("stop-color", function (d) {
            return d.stop_colorLine;
        });
        d3.select('.nvd3.nv-wrap.nv-line').moveToFront();
        d3.selectAll('.nv-focus .nv-groups').selectAll('.nv-area').style("fill", "url(#multiColorAreaGradient)");
        this.createLegendGradient();
        this.showMicroGridLegend('Min', 'Max');
    }
    calculateWidth(i, startTime, selectedLivePrices, endTime, xScale) {
        const xStartTime = i === 0 ? startTime : moment(startTime).add(i, 'hours').startOf('hour').valueOf();
        const x2StartTime = i === selectedLivePrices.length - 1 ? endTime : moment(xStartTime).add(1, 'hours').startOf('hour').valueOf();
        const x = xScale(xStartTime);
        const x2 = xScale(x2StartTime);
        const width = x2 - x;
        return width;
    }
    addWeatherData() {
        if (!this.configuration.isWeatherApiSupportedForTheLocation) {
            return;
        }
        let that = this;
        var data = [];
        let requestFilledCompletely = true;
        let from = moment(this.from).startOf('day');
        let to = moment(this.to).add(1, 'days').endOf('day');
        let begin = from.clone();
        while (begin.isSameOrBefore(to)) {
            let weatherData = this.weatherDataCache.get(begin.valueOf());
            requestFilledCompletely = requestFilledCompletely && weatherData != null && weatherData.length > 0;
            if (weatherData != null) {
                data.push(weatherData);
            }
            if (!requestFilledCompletely) {
                break;
            }
            begin.add(1, 'days').startOf('day').valueOf();
        }
        data = _.flatten(data);
        if (!requestFilledCompletely) {
            Utils.getJSONAsync(this.configuration.constructUrl("getWeatherData") + "&from=" + from.valueOf() + "&to=" + to.valueOf() + "&scope=HOURLY", dataIn => {
                let data = dataIn;
                let begin = from.clone();
                while (begin.isSameOrBefore(to)) {
                    let weatherData = _.filter(data, (d) => {
                        return moment(d.time).isSame(begin, 'day');
                    });
                    if (weatherData != null && weatherData.length > 0) {
                        that.weatherDataCache.set(begin.valueOf(), weatherData);
                    }
                    begin.add(1, 'days').startOf('day').valueOf();
                }
                that.addWeatherDataOnAxis(data);
            });
        }
        else {
            this.addWeatherDataOnAxis(data);
        }
    }
    addWeatherDataOnAxis(data) {
        let weather = Utils.parseWeatherData(data, this.weatherDescriptions);
        for (let i = 0; i < data.length; i++) {
            if (Math.abs((new Date()).getTime() - data[i].time) < 60 * 60 * 1000) {
                $('#currentWeatherIcon').text(data[i].iconCharacter);
                $('#currentWeatherIcon').attr('title', data[i].description in this.weatherDescriptions ? 'aktuell ' + this.weatherDescriptions[data[i].description] : "");
                $('#currentWeatherText').text(data[i].temperatureHigh.toFixed() + '\u202f°C');
                break;
            }
        }
        d3.selectAll(this.chartElement + " .nv-x .tick").each(function () {
            const date = d3.select(this).data()[0];
            let weatherDatum;
            if (weather[date.getDate() - 1]) {
                for (let entry of weather[date.getDate() - 1]) {
                    if (entry.hour === date.getHours()) {
                        weatherDatum = entry;
                    }
                }
            }
            if (!weatherDatum) {
                return;
            }
            d3.select(this)
                .append('text')
                .attr('class', 'weatherData')
                .attr('dy', '2em')
                .attr('style', "text-anchor: middle; font-family: 'discovergy-portal'; font-size: 1.25em; fill: " + weatherDatum.color)
                .html(function (d) {
                return "<title>" + weatherDatum.description + "</title>" + weatherDatum.icon;
            });
            d3.select(this)
                .append('text')
                .attr('class', 'weatherData')
                .attr('dy', '4em')
                .attr('style', "text-anchor: middle;")
                .text(function (d) {
                return weatherDatum.temperatureHigh.toFixed() + '\u202f°C';
            });
        });
    }
}
;
;
