<style>

.deviceIcon i {
  font-family: 'discovergy-portal' !important;
  font-size: 49px;
  padding: 10px;
  cursor: pointer;
}

.deviceIcon i.invert {
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
}

.hiddenElement {
  visibility: hidden;
}

#jr_chrome > div.jr_icon {
  background-image: url("/images/browser_chrome.png") !important;
  background-size: contain !important;
}

#jr_firefox > div.jr_icon {
  background-image: url("/images/browser_firefox.png") !important;
  background-size: contain !important;
}

#toast-container > div {
  opacity: 1 !important;
}

</style>

<template>
  <div>
    <div id="chart-controls">
      <nav class="chartControls" v-on:click.prevent v-if="showControls">

        <a href="#" v-bind:class="{active: activeItem=='loadprofile'}" class="loadprofile"
           v-if="isRLMDashboard && container!=null && container.measurementType=='ELECTRICITY'"
           :title="getTranslatedTitle('loadProfileView')"
           @click="selectionHandler('loadprofile','LOAD_PROFILE')">

          <img src="/images/loadprofile-icon.svg"></a>
        <a href="#" v-bind:class="{active: activeItem=='standard'}" class="standard"
           v-if="container==null || container.measurementType!='GAS'"
           :title="getTranslatedTitle('standardView')"
           @click="selectionHandler('standard','STANDARD')">
          <img src="/images/standard-icon.svg">
        </a>
        <a href="#" v-bind:class="{active: activeItem=='threephase'}" class="threephase"
           v-if="container!=null && container.hasThreePhase"
           :title="getTranslatedTitle('threePhaseView')"
           @click="selectionHandler('threephase','THREE_PHASE')">
          <img src="/images/threephase-icon.svg"></a>
        <a href="#" v-bind:class="{active: activeItem=='disaggregation'}" class="disaggregation"
           v-if="container!=null && container.disaggregationDataAvailable && (container.showDisaggregationView || isAdminSession)"
           :title="getTranslatedTitle('disaggregationView')"
           @click="selectionHandler('disaggregation','DISAGGREGATION')"
           style="background: url(/images/beta.png) no-repeat;background-size: contain;background-position-x: 22px;background-position-y: -3px;">
          <img src="/images/bulb-icon.svg">

        </a>
        <a href="#" v-bind:class="{active: activeItem=='microgridview'}" class="microgridview"
           v-if="container!=null && container.solution=='PRODUCTION_CONSUMPTION'"
           :title="getTranslatedTitle('microgridView')"
           @click="selectionHandler('microgridview','MICROGRID_CONSUMER')"><img src="/images/euro-icon.svg"></a>
        <a href="#" v-bind:class="{active: activeItem=='marketpricesview'}" class="marketpricesview"
           v-if="showMarketPricesView"
           :title="getTranslatedTitle('marketPricesView')"
           @click="selectionHandler('marketpricesview','MARKET_PRICES')"><img src="/images/market_price_icon.svg">

        </a>
        <a href="#" v-bind:class="{active: activeItem=='standard'}" class="standard text"
           v-if="container!=null && container.measurementType=='GAS'"
           :title="getTranslatedTitle('gasAsVolumeView')"
           @click="selectionHandler('standard','STANDARD_GAS')"
           style="padding: 15px 15px !important;">m³/h</a>
        <a href="#" v-bind:class="{active: activeItem=='gastoelectricity'}" class="gastoelectricity text"
           v-if="container!=null && container.measurementType=='GAS'"
           :title="getTranslatedTitle('gasAsElectricityView')"
           @click="selectionHandler('gastoelectricity','ELECTRICITY')"
           style="padding: 15px 15px !important;">kW</a>
      </nav>

      <i id="market-prices-info-icon" v-if="showMarketPricesView" aria-hidden="true" class="fa fa-info-circle"
         style="color: darkorange;top: -9px;right: 25px;position: relative;border-radius: 10px" @mouseover="showMarketPricesInfo()"></i>

      <select v-model="selectedDisaggregationAlgorithm" v-if="solution==9 && isAdminSession" id="algorithmSelect" style="height: 45px">
        <option>Discovergy</option>
        <option>Bidgely</option>
      </select>

    </div>
    <div class="liveInfoPanel" id="live-info-panel" style="display: none; overflow-x: auto; border-bottom: rgba(51, 51, 51, 0.13) 1px solid;">
      <table style="width: 100%">
        <tr>
          <td v-if="companyLogo" style="padding-left: 10px">
            <a v-bind:class="companyLogoClass" href="/"
               v-bind:style="{background:'url(' + companyLogo + ')' +' no-repeat' , backgroundSize:'contain',display:'block',width:'252px',height:'50px'}"></a>
          </td>
          <td>
            <flowGraphic :configuration="configuration" :measurementType="measurementType"
                         :liveCycleHook="liveCycleHook"
                         v-if="widgetType!=='CHART_WIDGET' && widgetType!=='METERS_WIDGET'"
                         :convertToMicrogrid="convertToMicrogrid"
                         :solution="solution"></flowGraphic>
          </td>
          <td class="deviceIcon" v-show="solution===9">
            <li v-for="s in renderedSeries" style="display: inline-block">
              <i v-bind:class="getIconClass(s)" @click="toggleSeriesVisibility(s.seriesType)"
                 :style="getColor(s)"
                 :title="getDeviceName(s)"></i>
            </li>
          </td>
          <td>
            <countersGraphic :configuration="configuration" :liveCycleHook="liveCycleHook" :measurementType="measurementType"
                             :solution="solution"
                             v-if="widgetType!=='CHART_WIDGET' && widgetType!=='FLOW_GRAPHIC_WIDGET'"></countersGraphic>
          </td>
        </tr>
      </table>
    </div>

    <chart :configuration="configuration" :measurementType="measurementType" :solution="solution" :type="type"
           v-if="widgetType=='CHART_WIDGET' || widgetType=='COMBINED_WIDGET'|| type=='PORTAL'" :visibilityPropagater="visibilityPropagater"
           :selectedDisaggregationAlgorithm="selectedDisaggregationAlgorithm" style=""></chart>
  </div>

</template>


<script>
import Vue from "vue";
import FlowGraphic from "./FlowGraphic.vue";
import CountersGraphic from "./CountersGraphic.vue";
import Chart from "./Chart.vue";
import Utils from "../utils";
import LiveCycleLoop from "../liveCycleLoop";
import {Configuration, isRLMDashboard, Solution} from '../configuration';

const OFFSET_MEASUREMENT_COUNT = 10;
export default Vue.extend({
  name: 'dashboard',
  props: ['type', 'containerId', 'widgetType', 'tokenId', 'companyLogo', 'companyLogoClass', 'isRLMDashboard', 'isAdminSession', 'userGroupSettings', 'userGroupId'],
  data: function () {
    return this.initialState();
  },

  components: {
    FlowGraphic,
    CountersGraphic,
    Chart

  },

  methods: {
    initialState() {
      return {
        container: null,
        configuration: null,
        reset: false,
        liveCycleHook: null,
        solution: this.isRLMDashboard ? Solution.LOAD_PROFILE : Solution.STANDARD,
        measurementType: 'ELECTRICITY',
        activeItem: this.isRLMDashboard ? 'loadprofile' : 'standard',
        convertToMicrogrid: false,
        seriesVisibility: new buckets.Dictionary(),
        visibilityPropagater: {seriesType: 'CONSUMPTION_BASELOAD', value: true},
        liveDataCallbackId: null,
        renderedSeries: [],
        showMarketPricesView: false,
        selectedDisaggregationAlgorithm: 'Discovergy',
      }
    },

    resetFields() {
      let initialData = this.initialState();
      // Iterate through the props
      for (let prop in initialData) {
        // Reset the prop locally.
        this[prop] = initialData[prop];
      }
    },

    fetchContainer(selectedOption) {
      var that = this;
      let c = new Configuration();
      c.type = that.type;
      c.widgetType = that.widgetType;
      c.tokenId = that.tokenId;
      c.containerId = selectedOption;
      Utils.getJSONAsync(c.constructUrl('getContainer'), function (container) {

        let configuration = new Configuration();
        //PORTAL/WIDGET
        configuration.type = that.type;
        configuration.widgetType = that.widgetType;
        configuration.tokenId = that.tokenId;
        configuration.containerId = selectedOption;
        configuration.lowerBound = container.firstMeasurementTime;
        configuration.upperBound = container.lastMeasurementTime;
        configuration.firstLoadMeasurementTime = container.firstLoadMeasurementTime;
        configuration.lastLoadMeasurementTime = container.lastLoadMeasurementTime - 1;
        configuration.isWeatherApiSupportedForTheLocation = container.isWeatherApiSupportedForTheLocation;

        // special case like where load-profiles available but no measurements
        if (configuration.upperBound === 0 && configuration.lastLoadMeasurementTime > configuration.upperBound) {
          configuration.upperBound = configuration.lastLoadMeasurementTime;
        }

        if (configuration.lowerBound === 0 && configuration.firstLoadMeasurementTime > configuration.lowerBound) {
          configuration.upperBound = configuration.lastLoadMeasurementTime;
        }

        configuration.solution = that.isRLMDashboard ? "LOAD_PROFILE" : container.solution;
        //configuration.solution = container.solution;

        if (
            container.tariff_monthlyBasePrice !== null &&
            (container.tariff_pricePerKWh !== null ||
                container.tariff_secondaryPricePerKWh !== null)
        ) {
          configuration.tariff = {
            monthlyBasePrice: container.tariff_monthlyBasePrice,
            pricePerKWh: container.tariff_pricePerkWh,
            secondaryPricePerKWh: container.tariff_secondaryPricePerkWh
          };
        }

        configuration.feedInTariff = container.feedInRemuneration;
        configuration.measurementInterval = container.measurementInterval;
        configuration.measurementType = container.measurementType;


        configuration.originalSolution = Solution[container.solution];
        if (container.producerType != null) {
          configuration.producerType = container.producerType.toLowerCase();
        }
        configuration.producerKey = container.producerKey;

        configuration.meterDisconnected = container.offline;
        configuration.loadProfileType = container.loadProfileType;

        if (that.userGroupId === 35 && configuration.loadProfileType === 'RLM') {
          configuration.loadProfileType = 'SLP';
        }
        configuration.isRLMDashboard = that.isRLMDashboard;
        configuration.focusChartDataSource = configuration.constructUrl('getPower');
        configuration.contextChartDataSource = configuration.constructUrl('getConsumption');
        configuration.flowGraphicDataSource = configuration.constructUrl('getLast');
        if (that.solution == Solution.LOAD_PROFILE) {
          //configuration.focusChartDataSource = configuration.getResourcePath() + 'get_rlm_measurements?meterId=' + container.meterIds[0];
          configuration.focusChartDataSource = configuration.constructUrl('getSemanticLoadProfile');
          configuration.contextChartDataSource = configuration.constructUrl('getSemanticLoadProfile');
        }

        if (container.firstMeasurementTime != 0) {
          that.liveCycleHook = LiveCycleLoop.getInstance(configuration.flowGraphicDataSource, (container.liveCycleStartTime - (OFFSET_MEASUREMENT_COUNT * container.measurementInterval)), container.measurementInterval);

          that.showControls = that.type == 'PORTAL';
          if (that.showControls) {
            $('#portal-nav').show();
            $('#readings-page-link').show();
            $('#chart-controls').appendTo($('#controls'));
          }
          $('#live-info-panel').show();
        } else {
          $('#portal-nav').hide();
          $('#live-info-panel').hide();
          $('#readings-page-link').hide();
          that.showControls = false;
        }

        configuration.houseIconColor = container.houseIconColor;
        configuration.consumptionColor = container.consumptionColor;


        configuration.colors.set('house_icon', container.houseIconColor)
        configuration.colors.set("consumption", container.graphConsumptionColor);
        configuration.colors.set("consumption_tariff1", that.userGroupSettings['TARIFF_1_COLOR'] != null ? that.userGroupSettings['TARIFF_1_COLOR'] : '#008739');
        configuration.colors.set("consumption_tariff2", that.userGroupSettings['TARIFF_2_COLOR'] != null ? that.userGroupSettings['TARIFF_2_COLOR'] : '#086FA7');
        configuration.colors.set("feed", that.userGroupSettings['FEED_COLOR'] != null ? that.userGroupSettings['FEED_COLOR'] : '#FF8C00');
        configuration.colors.set("feed_tariff1", that.userGroupSettings['TARIFF_1_FEED_COLOR'] != null ? that.userGroupSettings['TARIFF_1_PRODUCTION_COLOR'] : '#FF8C00');
        configuration.colors.set("feed_tariff2", that.userGroupSettings['TARIFF_2_FEED_COLOR'] != null ? that.userGroupSettings['TARIFF_2_PRODUCTION_COLOR'] : '#ff7f50');

        configuration.colors.set("min_line_color", that.userGroupSettings['MIN_LINE_COLOR'] != null ? that.userGroupSettings['MIN_LINE_COLOR'] : '#FFD700');
        configuration.colors.set("max_line_color", that.userGroupSettings['MAX_LINE_COLOR'] != null ? that.userGroupSettings['MAX_LINE_COLOR'] : '#CC220A');
        configuration.colors.set("mean_line_color", that.userGroupSettings['MEAN_LINE_COLOR'] != null ? that.userGroupSettings['MEAN_LINE_COLOR'] : '#0E408D');


        that.configuration = configuration;
        that.container = container;
        that.measurementType = container.measurementType;
        that.showMarketPricesView = container.showMarketPricesView;

        console.log("solution:");
        console.log(configuration.solution);
        console.log("original solution:");
        console.log(configuration.originalSolution);

      });
    },

    selectionHandler(item, targetSolution) {
      this.activeItem = item;
      if (targetSolution == 'ELECTRICITY') {
        this.measurementType = 'ELECTRICITY';
        this.resetLiveCycle(this.configuration);
      } else if (targetSolution == 'STANDARD_GAS') {
        this.measurementType = 'GAS';
        this.resetLiveCycle(this.configuration);
      } else if (targetSolution == 'STANDARD_WATER') {
        this.measurementType = 'WATER';
        this.resetLiveCycle(this.configuration);
      } else if (targetSolution == 'STANDARD_HEAT') {
        this.measurementType = 'HEATING';
        this.resetLiveCycle(this.configuration);
      } else {
        this.switchView(targetSolution);
      }
    },

    switchView(targetSolution) {
      $.xhrPool.abortAll();
      this.solution = targetSolution === 'STANDARD' ? this.configuration.originalSolution : Solution[targetSolution];
      var configuration = Configuration.getModifiedConfiguration(this.configuration, this.solution);
      this.resetLiveCycle(configuration);
      this.convertToMicrogrid = configuration.convertToMicrogrid;

      if (typeof ga !== 'undefined') {
        ga('send', {
          hitType: 'pageView',
          page: '/dashboard/#' + this.activeItem
        });
      }

    },

    resetLiveCycle(configuration) {
      var since;
      if (typeof lastDataReceivedAt != 'undefined') {
        since = lastDataReceivedAt - (OFFSET_MEASUREMENT_COUNT * this.container.measurementInterval);
      } else {
        since = this.container.liveCycleStartTime - (OFFSET_MEASUREMENT_COUNT * this.container.measurementInterval);
      }
      this.liveCycleHook = LiveCycleLoop.getInstance(configuration.flowGraphicDataSource, since, this.container.measurementInterval);
      this.liveCycleHook.resetTo(since);
      if (this.liveDataCallBackId) {
        this.liveCycleHook.unregister(this.liveDataCallBackId);
      }
      if (this.solution == Solution.DISAGGREGATION) {
        this.liveDataCallBackId = this.liveCycleHook.registerFordata(this.updateDisaggregationWithLiveData);
      }
    },

    toggleSeriesVisibility(seriesType) {
      var visibility = false;
      if (this.seriesVisibility.get(seriesType) !== undefined) {
        visibility = !this.seriesVisibility.get(seriesType);
      }

      this.seriesVisibility.set(seriesType, visibility);
      this.visibilityPropagater = {'seriesType': seriesType, 'value': visibility};
      //this.$emit('seriesVisibilityChanged', {'key': key, 'value': visibility});
    },

    getColor(s) {
      var visibility = this.seriesVisibility.get(s.seriesType);

      if (visibility != undefined && !visibility) {

        //return '#d7dbdd';
        return {
          color: s.color,
          backgroundColor: 'transparent',
          margin: '10px',
          display: 'block'
        };
      }


      return {
        color: '#FFFFFF',
        backgroundColor: s.color,
        margin: '10px',
        display: 'block'
      };
    },

    getIconColor(s) {

      var visibiliy = this.seriesVisibility.get(s.seriesType);

      if (visibiliy != undefined && !visibiliy) {

        //return '#d7dbdd';
        return s.color;
      }


      return '#FFFFFF';

    },

    getBackgroundColor(s) {

      var visibiliy = this.seriesVisibility.get(s.seriesType);

      if (visibiliy != undefined && !visibiliy) {
        return 'transparent';
      }
      return s.color;


    },

    getIconClass(s) {
      var visibiliy = this.seriesVisibility.get(s.seriesType);

      if (visibiliy != undefined && !visibiliy) {

        //return '#d7dbdd';
        return s.iconClass;
      }

      return s.iconClass + " invert";
    },

    updateDisaggregationWithLiveData(data) {

      var that = this;
      that.renderedSeries.forEach(function (re) {
        re.power = -1;
      });

      data = _.filter(Utils.mapToDataSeries(data), function (s) {
        return s.dimension === 'POWER';
      });

      data.forEach(function (s) {
        if (s.seriesType !== null && s.seriesType.indexOf('CONSUMPTION_DEVICE_') !== -1) {
          var r = _.find(that.renderedSeries, function (rs) {
            return rs.seriesType === s.seriesType;
          });

          var power = Utils.getLastValue(s).value;
          if (r) {
            r.power = power;
          } else {
            that.renderedSeries.push({
              'seriesType': s.seriesType,
              'key': s.key,
              'color': tinycolor(s.color).setAlpha(1).toRgbString(),
              'iconClass': 'disco-' + (s.seriesType.replace('CONSUMPTION_DEVICE_', '').toLowerCase()),
              'order': s.sortIndex,
              'power': power
            });
          }
        }
      });
      that.renderedSeries = _.sortBy(that.renderedSeries, function (s) {
        return s.order;
      });
    },

    showMarketPricesInfo() {
      toastr.options = {
        'closeButton': true,
        'debug': false,
        'preventDuplicates': true,
        'showDuration': '300',
        'hideDuration': '1000',
        'timeOut': '60000',
      };
      toastr.info('Die hier abgebildeten Börsenstrompreise spiegeln Stromangebot und -nachfrage wieder. ' +
          'Beim Verbrauchen in den grünen Zeitintervallen tragen Sie dazu bei, ein klimafreundliches Gleichgewicht zwischen Erzeugung und Verbrauch zu erreichen. ' +
          'Kunden mit einem variablen Tarif senken zusätzlich ihre Stromkosten. <a href=https://discovergy.com/blog/boersenstrompreise-ansicht target=_blank><strong>Mehr Infos</strong></a>');
    },

    initDashboard() {
      $('#chart-controls').appendTo($('#controls'));

      /*        $.reject({
                reject: {
                  msie: true, // Microsoft Internet Explorer
                  konqueror: true, // Konqueror (Linux)
                  unknown: true,// Everything else
                },
                display: ['chrome', 'firefox'],
                imagePath: '/images/',
                closeCookie: true,
                // Pop-up Window Text
                header: i18next.t('header'),

                paragraph1: i18next.t('paragraph1'),

                paragraph2: i18next.t('paragraph2'),

                // Allow closing of window
                close: true,

                // Message displayed below closing link
                closeMessage: i18next.t('closeMessage'),
                closeLink: i18next.t('closeLink'),
              });*/

      $.xhrPool = [];
      $.xhrPool.abortAll = function () {
        _.each(this, function (jqXHR) {
          jqXHR.abort();
        });
      };
      $.ajaxSetup({
        beforeSend: function (jqXHR) {
          $.xhrPool.push(jqXHR);
        }
      });


      this.fetchContainer(this.containerId);

      if (typeof ga !== 'undefined') {
        ga('send', {
          hitType: 'pageView',
          page: '/dashboard/#' + this.activeItem
        });
      }
    },

    getDeviceName(s) {
      let device = Utils.toCamelCase(s.seriesType.replace('CONSUMPTION_DEVICE_', ''));
      return i18next.t([device, s.key]);
    },

    getTranslatedTitle(title) {
      return i18next.t(title);
    }

  },

  mounted: function () {

    this.initDashboard();


  },

  created: function () {
    var that = this;
    this.$on('chartUpdated', function (d) {
      that.renderedSeries = _.filter(that.renderedSeries, function (rs) {
        return rs.power != -1;
      });
      d.forEach(function (s) {
        var r = _.find(that.renderedSeries, function (rs) {
          return rs.seriesType === s.seriesType;
        });
        if (r == undefined) {
          that.renderedSeries.push(s);
        }
      });


      that.renderedSeries = that.renderedSeries.filter((s) => {
        let index = s.seriesType.toLowerCase().indexOf('bidgely');
        return that.selectedDisaggregationAlgorithm === 'Discovergy' ? index == -1 : index != -1;
      });


      that.renderedSeries = _.sortBy(that.renderedSeries, function (s) {
        return s.order;
      });
    });
  },

  watch: {
    containerId: {
      handler: function (val, oldVal) {
        this.resetFields();
        this.fetchContainer(val);
      }
    },

    measurementType: {
      handler: function (val, oldVal) {
        this.liveCycleHook = LiveCycleLoop.getInstance(this.configuration.flowGraphicDataSource, (this.container.liveCycleStartTime - (OFFSET_MEASUREMENT_COUNT * this.container.measurementInterval)), this.container.measurementInterval);
      }
    }
  }
});

</script>
