//TODO move tooltip and chart css here
<style>
  #chart1 {
    height: 50%;
  }

  #chart2 {
    height: 50%;
  }


</style>
<template>


  <div id="chartContainer" style="display: block">

    <svg id="chart1"></svg>
    <svg id="chart2"></svg>

  </div>


</template>

<script>
  import Vue from "vue";
  import Configuration from '../configuration.ts';
  import Portal from "../portal.ts";

  export default Vue.extend({
    name: 'Chart',
    props: ['configuration', 'solution', 'measurementType', 'type', 'visibilityPropagater', 'selectedDisaggregationAlgorithm'],
    data() {
      return {
        portal: null
      }
    },

    mounted() {
      var chart_height = $(window).height();
      console.log('window height:' + chart_height);
      if (this.type === 'PORTAL') {
        chart_height = chart_height - 185;
      }
      if (chart_height < 300)
        chart_height = 300;
      
      $("#chartContainer").height(chart_height);
    },
    
    methods: {
      initPortal(configuration) {
        
        
        if (!configuration) {
          configuration = this.configuration;
        }
        
        if (this.portal) {
          this.portal.destroy();
        }
        
        if (configuration.solution === "LOAD_PROFILE" && configuration.lastLoadMeasurementTime <= 0) {
          $('<div class="noLoadProfile" style="position: fixed; height:  100%;width: 100%;text-align:  center;"><h2 style="margin-top: 250px;">' + i18next.t('noLoadProfiles') + '</h2></div>').insertAfter('.liveInfoPanel');
          return;
        }
        
        $(".noLoadProfile").remove();
        
        this.portal = new Portal(configuration);
        var that = this;
        this.portal.setOnUpdateCallback(() => {
          that.$parent.$emit('chartUpdated', that.portal.getDeviceIcons());
          
        });
      },
      
    },
    watch: {
      configuration: {
        //triggers when the container selection changes
        handler: function (val, oldVal) {
          console.log("config changed");
          this.initPortal();
        }
      },
      solution: {
        //triggers when view mode (from chart control) changes
        handler: function (val, oldVal) {
          console.log("solution changed");
          var configuration = Configuration.getModifiedConfiguration(this.configuration, this.solution);
          //11="LOAD_PROFILE"
          if (oldVal === 11 || val === 11) {
            this.initPortal(configuration);
          }
          else if (this.portal) {
            this.portal.updateDataSources(configuration);
          }
        },
      },
      visibilityPropagater: {
        handler: function (val, oldVal) {
          this.portal.setSeriesVisibility(val.seriesType, val.value);
        },
      },
      measurementType: {
        handler: function (val, oldVal) {
          console.log("measurementType changed");
          if (this.portal) {
            this.portal.updateActiveMeasurementType(this.measurementType);
          }
        }
      },
      selectedDisaggregationAlgorithm: {
        handler: function (val, oldVal) {
          if (this.portal) {
            this.portal.render(val);
          }
        }
      }
    }
  });
</script>
