<style>
  .counterWrap, .counterWrapReactive {
    display: inline-block;
    vertical-align: bottom;
    margin: 5px 6px 5px 20px;
  }

  .counterWrapWrapper, .counterWrapWrapperReactive {
    margin: 5px 0 5px 0;
  }

  .counterWrap .counter, .counterWrapReactive .counter {
    width: 303px;
    height: 36px;
  }

  .counterWrap .counterLabel, .counterWrapReactive .counterLabel {
    font-weight: bold;
    margin-right: 12px;
    white-space: nowrap;
  }

  .counterWrap table, .counterWrapReactive table {
    margin-right: 12px;
  }

</style>

<template>
  <div style="display: inline;">
    <div class="counters" style=" display: inline;">
      <div class="counterWrapWrapper" style="float: right; text-align: right;">
        <div class="counterWrap unidirectional" v-if="unidirectionalCounter1Value!=null">
          <table cellspacing="0" cellpadding="0">
            <tr v-bind:title="getCounterTitle(unidirectionalCounter1Value)" :class="[unidirectionalCounter1Value.key + 0]">
              <td><span class="counterLabel">{{unidirectionalCounter1Value.label}}</span></td>
              <td>
                <div class="counter" v-bind:data-clipboard-text="unidirectionalCounter1Value.meterSerialNumber">
                  {{draw(unidirectionalCounter1Value, 0, true)}}
                </div>
              </td>
            </tr>
          </table>
        </div>

        <div class="counterWrap bidirectional">

          <table cellspacing="0" cellpadding="0">
            <tr v-for="(reading, index) in bidirectionalMeterReadings" :class="[reading.key + index]" v-bind:title="getCounterTitle(reading)"
                v-if="reading.value!=0">
              <td><span class="counterLabel">{{reading.label}}</span></td>
              <td>
                <div class="counter" v-bind:data-clipboard-text="reading.meterSerialNumber">
                  {{draw(reading, index)}}
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>

    <div class="countersRLM" style="float: right;" v-if="configuration!=null && configuration.loadProfileType === 'RLM'">
      <div class="counterWrapWrapperReactive">
        <div class="counterWrapReactive unidirectional" v-if="unidirectionalCounter1Value!=null && unidirectionalCounter1Value.reactiveValue>0">
          <table cellspacing="0" cellpadding="0">

            <tr v-bind:title="getCounterTitle(unidirectionalCounter1Value, true)" :class="[unidirectionalCounter1Value.key + 0]">
              <td style="text-align: right"><span class="counterLabel">{{unidirectionalCounter1Value.reactiveLabel}}</span></td>
              <td>
                <div class="counter" v-bind:data-clipboard-text="unidirectionalCounter1Value.meterSerialNumber">
                  {{drawReactive(unidirectionalCounter1Value, 0, true)}}
                </div>
              </td>
            </tr>
          </table>
        </div>

        <div class="counterWrapReactive bidirectional">
          <table cellspacing="0" cellpadding="0">
            <tr v-for="(reading, index) in bidirectionalMeterReadings" :class="[reading.key + index]" v-bind:title="getCounterTitle(reading, true)"
                v-if="reading.reactiveValue!=0">
              <td style="text-align: right"><span class="counterLabel">{{reading.reactiveLabel}}</span></td>
              <td>
                <div class="counter" v-bind:data-clipboard-text="reading.meterSerialNumber">
                  {{drawReactive(reading, index)}}
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
  import Utils from '../utils.ts';

  export default {
    name: 'CountersGraphic',
    props: ['configuration', 'liveCycleHook', 'measurementType', 'solution'],

    data() {
      return this.initialState();
    },

    mounted() {
      new Clipboard('.counter');
      this.registerHandler();
    },

    methods: {

      draw(reading, index, unidirectional) {
        let id = '.counterWrap' + (unidirectional ? '.unidirectional' : '.bidirectional') + ' .' + reading.key + index;
        let unit = (this.measurementType === 'ELECTRICITY' || this.measurementType === 'HEATING') ? 'kWh' : 'm³';
        this.drawCounter(id, reading.value, unit, reading.color);
      },

      drawReactive(reading, index, unidirectional) {
        let id = '.counterWrapReactive' + (unidirectional ? '.unidirectional' : '.bidirectional') + ' .' + reading.key + index;
        let unit = this.measurementType == 'ELECTRICITY' ? 'kvarh' : 'm³';
        this.drawCounter(id, reading.reactiveValue, unit, reading.color);

      },

      drawCounter(countnerId, value, unit, color) {
        let that = this;
        this.$nextTick(function () {

          if (that.meterCounters.get(countnerId) == null) {
            that.meterCounters.set(countnerId, new Counter(countnerId + ' .counter'));
          }

          let counter = that.meterCounters.get(countnerId);
          counter.drawFormatted(value, unit, that.solution == 7 ? color : null);
        });
      },

      initialState() {
        return {

          unidirectionalCounter1: new Counter('.counterWrap.unidirectional .counter1 .counter'),
          unidirectionalCounter1Value: null,

          meterCounters: buckets.Dictionary(),
          bidirectionalMeterReadings: [],

        }
      },
      registerHandler() {
        let that = this;
        let liveCycle = this.liveCycleHook;

        if (liveCycle) {
          liveCycle.registerFordata(function (data) {
            // console.log('data:' + data);
            if (data == null) {
              return;
            }


            data.forEach(function (s) {
              if (s.type == 'ENERGY' || s.type == 'VOLUME') {
                s.seriesType = s.seriesKey.toUpperCase();
              }
            });

            let dataSeries = Utils.mapToDataSeries(data);


            let filteredSeries = _.filter(dataSeries, s => (that.measurementType == 'ELECTRICITY' || that.measurementType === 'HEATING' ? 'ENERGY' : 'VOLUME') === s.dimension);
            let filteredLastValues = [];
            for (let s of filteredSeries) {
              var lastValue = Utils.getLastValue(s);
              lastValue.label = that.getLabel(s.seriesType);
              lastValue.reactiveLabel = that.getLabel(s.seriesType, true);
              lastValue.key = s.key;
              lastValue.color = that.configuration.colors.get(s.key);
              if (s.seriesType !== 'PRODUCTION' && s.key.indexOf('energyProducer') == -1) {
                filteredLastValues.push(lastValue);
              }
            }

            if (that.configuration.isRLMDashboard && that.solution == 11) {
              let consumptionTariff1 = _.find(filteredLastValues, (s) => {
                return s.key == 'consumption_tariff1'
              });
              let consumptionTariff2 = _.find(filteredLastValues, (s) => {
                return s.key == 'consumption_tariff2'
              });

              let feedTariff1 = _.find(filteredLastValues, (s) => {
                return s.key == 'feed_tariff1'
              });
              let feedTariff2 = _.find(filteredLastValues, (s) => {
                return s.key == 'feed_tariff2'
              });

              if (consumptionTariff1 && consumptionTariff2) {
                let consumption = jQuery.extend({}, consumptionTariff1);
                consumption.value = consumptionTariff1.value + consumptionTariff2.value;
                consumption.reactiveValue = consumptionTariff1.reactiveValue + consumptionTariff2.reactiveValue;
                consumption.label = that.getLabel('CONSUMPTION');
                consumption.reactiveLabel = that.getLabel('CONSUMPTION', true);
                consumption.key = "consumption";

                filteredLastValues.push(consumption);
                filteredLastValues = _.filter(filteredLastValues, (s) => {
                  return s.key.indexOf('consumption_tariff') == -1
                })
              }

              if (feedTariff1 && feedTariff2) {
                let feed = jQuery.extend({}, feedTariff1);
                feed.value = feedTariff1.value + feedTariff2.value;
                feed.reactiveValue = feedTariff1.reactiveValue + feedTariff2.reactiveValue;
                feed.label = that.getLabel('FEED');
                feed.reactiveLabel = that.getLabel('FEED', true);
                feed.key = "feed";
                filteredLastValues.push(feed);
                filteredLastValues = _.filter(filteredLastValues, (s) => {
                  return s.key.indexOf('feed_tariff') == -1
                })
              }

            }

            if (filteredLastValues.length > 0) {
              that.bidirectionalMeterReadings = filteredLastValues;
            }

            let productionSeries = _.find(filteredSeries, s => s.seriesType === 'PRODUCTION');
            if (productionSeries) {
              that.unidirectionalCounter1Value = Utils.getLastValue(productionSeries);
              that.unidirectionalCounter1Value.label = that.getLabel('PRODUCTION');
              that.unidirectionalCounter1Value.reactiveLabel = that.getLabel('PRODUCTION', true);
              that.unidirectionalCounter1Value.key = productionSeries.key;
            }

          });
        }
      },

      getCounterTitle(reading, reactive) {
        if (reading) {
          var title = i18next.t('meterSerialNumber')+': ' + reading.meterSerialNumber;

          if (reading.label.indexOf('(HT)') != -1 || reading.label.indexOf('(NT)') != -1) {
            if (reactive) {
              let unit = this.measurementType == 'ELECTRICITY' ? 'kvarh' : 'm³';
              title = reading.reactiveLabel + ': ' + reading.reactiveValue + ' ' + unit + ' ' + title;
            } else {
              let unit = (this.measurementType === 'ELECTRICITY' || this.measurementType === 'HEATING') ? 'kWh' : 'm³'
              title = reading.label + ': ' + reading.value + ' ' + unit + ' ' + title;
            }
          }
          title = title.replace('('+i18next.t('tariff1_Short')+')', '('+i18next.t('consumptionTariff1')+')');
          title = title.replace('('+i18next.t('tariff2_Short')+')', '('+i18next.t('consumptionTariff2')+')');
          return title;
        }
        return '';
      },
      resetFields() {
        let initialData = this.initialState();
        // Iterate through the props
        for (let prop in initialData) {
          // Reset the prop locally.
          this[prop] = initialData[prop];
        }
      },
      getLabel(seriesType, reactive) {

        let isRLMContainer = this.configuration.loadProfileType === 'RLM';
        var label = i18next.t('consumption');
        switch (seriesType) {
          case 'CONSUMPTION':
            label = i18next.t('consumption');
            break;
          case 'CONSUMPTION_TARIFF1':
            label = isRLMContainer ?  i18next.t('consumptionTariff1_Short') : i18next.t('consumptionTariff1') ;
            break;
          case 'CONSUMPTION_TARIFF2':
            label = isRLMContainer ? i18next.t('consumptionTariff2_Short')  : i18next.t('consumptionTariff2') ;
            break;
          case 'FEED':
            label = i18next.t('feed') ;
            break;
          case 'FEED_TARIFF1':
            label = i18next.t('feedTariff1_Short');
            break;
          case 'FEED_TARIFF2':
            label = i18next.t('feedTariff2_Short');
            break;
          case 'PRODUCTION':
            label = i18next.t('production');
            break;
          default:
            label = i18next.t('consumption');
        }

        label = (isRLMContainer ? (reactive ? i18next.t('reactive') : i18next.t('active'))+' ' : '') + label;
        return label;

      }
    },
    watch: {
      liveCycleHook: {
        handler: function (val, oldVal) {
          this.resetFields();
          if (val != null) {
            this.registerHandler();
          }
        }
      },

    }
  }

</script>
