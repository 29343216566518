"use strict";
import { Utils } from './utils';
class Chart {
    constructor(chartElement, configuration) {
        this.enableZoom = false;
        this.isZoomActive = false;
        this.dialAdded = false;
        this.seriesVisibility = new buckets.Dictionary();
        this.selectedDisaggregationAlgorithm = 'Discovergy';
        //TODO find a better way to remove all dirty elements
        d3.selectAll('.nvtooltip').remove();
        d3.selectAll(chartElement).selectAll('*').remove();
        this.chartElement = chartElement;
        this.configuration = configuration;
        this.activeMeasurementType = configuration.measurementType;
        this.locale = Utils.getCurrentD3Locale();
        this.weatherDescriptions = Utils.GERMAN_WEATHER_DESCRIPTIONS;
        this.localeFormat = this.locale.timeFormat.multi([[".%L", function (d) {
                    return d.getMilliseconds();
                }], [":%S", function (d) {
                    return d.getSeconds();
                }], ["%H:%M", function (d) {
                    return d.getMinutes();
                }], ["%H:%M", function (d) {
                    return d.getHours();
                }], ["%a %d.", function (d) {
                    return d.getDay() && d.getDate() != 1;
                }], ["%d. %b", function (d) {
                    return d.getDate() != 1;
                }], ["%B", function (d) {
                    return d.getMonth();
                }], ["%Y", function () {
                    return true;
                }]]);
    }
    ;
    render(data) {
        let that = this;
        this.updateSeriesVisibility(data);
        if (data != null) {
            this.data = data;
        }
        if (this.configuration.solution === "MARKET_PRICES") {
            this.data = _.filter(this.data, function (s) {
                return s.seriesType !== 'CONSUMPTION_HAPPYHOUR' && s.seriesType.startsWith('CONSUMPTION');
            });
        }
        if (this.configuration.convertToMicrogrid) {
            this.data = Utils.convertPVSeriesToMicroGrid(this.data, this.configuration.producerKey, this.configuration.feedInTariff);
        }
        this.onBeforeRender();
        /*filter extra series in case of microgrid for focusChart*/
        var filteredData = this.data.filter(function (d) {
            if (d.dimension == 'ENERGY') {
                return true;
            }
            return d.seriesType !== 'CONSUMPTION_FROM_PRODUCER';
        });
        if (this.configuration.measurementType == 'GAS') {
            if (this.activeMeasurementType == 'GAS') {
                filteredData = this.data.filter(function (s) {
                    return s.dimension == 'VOLUME' || s.dimension == 'VOLUME_FLOW';
                });
            }
            else {
                filteredData = this.data.filter(function (s) {
                    return s.dimension == 'ENERGY' || s.dimension == 'POWER';
                });
            }
        }
        if (this.configuration.measurementType == 'WATER') {
            filteredData = this.data.filter(function (s) {
                return s.dimension == 'VOLUME' || s.dimension == 'VOLUME_FLOW';
            });
        }
        if (this.configuration.solution === 'DISAGGREGATION') {
            filteredData = filteredData.filter((s) => {
                let index = s.seriesType.toLowerCase().indexOf('bidgely');
                return this.selectedDisaggregationAlgorithm === 'Discovergy' ? index == -1 : index != -1;
            });
        }
        //apply translations
        filteredData.map(d => {
            let seriesType = d.seriesType;
            if ('CONSUMPTION' === seriesType && this.configuration.solution === 'TWO_TARIFF') {
                seriesType = 'CONSUMPTION_TARIFF1';
            }
            d.key = seriesType ? i18next.t([Utils.toCamelCase(seriesType), d.key]) : d.key;
            if (this.configuration.solution === 'MICROGRID_CONSUMER') {
                d.key = Utils.getTranslatedMicrogridKey(d.key);
            }
            return d;
        });
        this.seriesAbsoluteMax = Utils.getSeriesMax(filteredData) * this.conversionFactor;
        d3.select(this.chartElement) //Select the <svg> element you want to render the chart in.
            .datum(filteredData) //Populate the <svg> element with chart data...
            .call(this.chart); //Finally, render the chart!
        if (this.resizeHandler) {
            this.resizeHandler.clear();
        }
        this.resizeHandler = nv.utils.windowResize(this.chart.update);
        if (this.isZoomActive) {
            this.showSummary();
        }
    }
    updateSeriesVisibility(data) {
        let that = this;
        data.forEach(function (s) {
            let isVisible = that.seriesVisibility.get(s.seriesType);
            if (isVisible !== undefined) {
                s.disabled = !isVisible;
            }
        });
    }
    updateChart(dataSource, parameters, callBack) {
        let that = this;
        this.from = parseInt(parameters.get("from"));
        this.to = parseInt(parameters.get("to"));
        //parameters.set("containerId", this.configuration.containerId);
        Utils.getData(dataSource, parameters, function (result) {
            that.preprocess(result);
            let data = Utils.mapToDataSeries(result);
            /*var filteredData = data;
            if (that.configuration.measurementType == 'GAS') {
              filteredData = Utils.filterData(data, that.activeMeasurementType);
            }
      
            that.seriesAbsoluteMax = Utils.getSeriesMax(filteredData) * that.conversionFactor;*/
            var timeStamps = [];
            data.forEach(function (s) {
                if (s.values != null && s.values.length !== 0) {
                    timeStamps.push(s.values[s.values.length - 1].x);
                }
            });
            var to = _.max(timeStamps);
            if (to > that.to) {
                that.to = to;
            }
            that.render(data);
            if (callBack) {
                callBack(data);
            }
        });
    }
    onInit() {
        this.chart.margin({ right: 40 }).noData(i18next.t('noData'));
        this.chart.yAxis.orient('left');
        let that = this;
        this.chart.yAxis.showMaxMin(true).ticks(8);
        this.chart.duration(0);
        this.chart.dispatch.on('renderEnd', function () {
            that.data.forEach(function (series) {
                that.seriesVisibility.set(series.seriesType, !series.disabled);
            });
            that.updateScopeLabel();
            that.adjustLabelPosition();
            if (that.enableZoom) {
                that.addZoomHandler();
            }
            that.addExportDataButton();
            if (that.navigationElement != null) {
                that.addNavigationDial();
                that.dialAdded = true;
            }
            that.onInitialize();
            that.onAfterRender();
            d3.selectAll(that.chartElement + " .dial").moveToFront();
            d3.selectAll('.zoomSummaryLayer').moveToFront();
            if (that.configuration.solution === 'DISAGGREGATION') {
                that.onUpdateCallback();
            }
        });
        this.chart.legend.dispatch.on('legendClick', function (targetSeries) {
            if (that.configuration.solution === 'THREE_PHASE') {
                for (var i = 0; i < that.data.length; i++) {
                    if (that.data[i].seriesType.indexOf(targetSeries.seriesType + '_OUT') != -1) {
                        that.data[i].disabled = !targetSeries.disabled;
                    }
                }
            }
        });
        nv.utils.inheritOptions(this, this.chart);
    }
    setBaseUnit(unit) {
        this.baseUnit = unit;
    }
    setConversionFactor(factor) {
        this.conversionFactor = factor;
    }
    setEnableZoom(value) {
        this.enableZoom = value;
    }
    setNavigationElement(element) {
        this.navigationElement = element;
    }
    onZoom(callback) {
        this.onZoomCallback = callback;
    }
    onResetZoom(callback) {
        this.onResetZoomCallback = callback;
    }
    onNavigate(callback) {
        this.onNavigateCallback = callback;
    }
    setOnUpdateCallback(callback) {
        this.onUpdateCallback = callback;
    }
    adjustLabelPosition() {
        d3.select(this.chartElement + " .nv-y .nv-axisMaxMin:last-child text").text(this.chart.yAxis.axisLabel());
        d3.select(this.chartElement + " .nv-y .nv-axisMaxMin.nv-axisMin-y text").text('');
        d3.select(this.chartElement + " .nv-y .nv-axislabel").text('');
        if (this.chart.yAxis2) {
            d3.select(this.chartElement + " .nv-y2 .nv-axisMaxMin:last-child text").text(this.chart.yAxis2.axisLabel());
            d3.select(this.chartElement + " .nv-y2 .nv-axisMaxMin.nv-axisMin-y text").text('');
            d3.select(this.chartElement + " .nv-y2 .nv-axislabel").text('');
        }
    }
    updateConfiguration(configuration) {
        this.configuration = configuration;
    }
    updateDataSource(dataSource) {
        this.dataSource = dataSource;
    }
    addZoomHandler() {
        d3.selectAll('.zoom, .zoomSummaryLayer, .zoomLayer').remove();
        let that = this;
        var clicked = false;
        let marginLeft = that.chart.margin().left;
        var startX = marginLeft;
        let height = $('.nv-lineChart .nv-background rect').attr('height');
        let width = parseInt($('.nv-lineChart .nv-background rect').attr('width'));
        //TODO fix this and find a correct method
        let zoomLayerHeight = 400;
        let zoomLayerWidth = 1000;
        if (height !== undefined) {
            zoomLayerHeight = height;
        }
        if (width !== undefined) {
            zoomLayerWidth = width;
        }
        d3.select('.nv-lineChart .nv-focus').append('g').attr("class", "zoom").append("rect").attr("display", "none")
            .attr("height", zoomLayerHeight).attr("fill", "rgba(255, 255, 255, 0.6)")
            .style('fill-opacity', 1).attr('class', 'zoomOverlay').style('stroke', '#808080')
            .style('stroke-width', '2px').style('stroke-dasharray', '10 5');
        let zoomOverlay = d3.select('.zoomOverlay');
        let zoomLayer = d3.select('.zoom').append("rect").style('fill-opacity', 0).attr('display', 'block')
            .attr("height", zoomLayerHeight).attr("width", zoomLayerWidth)
            .attr('class', 'zoomLayer');
        let zoomSummaryLayer = d3.select('.zoom').append('g').attr('class', 'zoomSummaryLayer');
        zoomSummaryLayer.append("rect").style('fill-opacity', 0.3).attr('display', 'none').attr('y', zoomLayerHeight - 30)
            .attr("height", 30).attr("width", zoomLayerWidth);
        $('.zoomLayer').off();
        $('.zoomLayer').on('mousedown', function (e) {
            startX = e.clientX - marginLeft;
            zoomOverlay.attr('x', startX);
            zoomOverlay.attr('display', 'block');
            clicked = true;
        });
        $('.zoomLayer').on('mousemove', function (e) {
            if (clicked) {
                if (e.clientX > zoomLayerWidth + marginLeft + 20) {
                    clicked = false;
                    that.onZoomFinish(e, startX);
                    return;
                }
                let w = e.clientX - marginLeft - startX;
                //console.log('width:' + w);
                if (w > 0) {
                    zoomOverlay.attr('width', w);
                }
            }
        });
        $('.zoomLayer').on('mouseup mouseleave', function (e) {
            if (clicked) {
                that.onZoomFinish(startX, e.clientX - marginLeft);
            }
            clicked = false;
        });
    }
    onZoomFinish(startX, stopX) {
        if ((stopX - startX) <= 0) {
            return;
        }
        d3.select('.zoomOverlay').attr('width', 0).attr('display', 'none');
        let from = this.chart.xAxis.scale().invert(startX).getTime();
        let to = this.chart.xAxis.scale().invert(stopX).getTime();
        if ((to - from) < 10 * this.configuration.measurementInterval) {
            to = from + (10 * this.configuration.measurementInterval);
        }
        let lastValue = Utils.getLastValue(this.data[0]);
        if (!lastValue || from > lastValue.time) {
            return;
        }
        if ((to - from) > 0) {
            this.isZoomActive = true;
            this.onZoomCallback(from, to);
        }
    }
    showSummary() {
        if (this.isZoomActive) {
            var zoomLayerData = [];
            let that = this;
            var filteredData = _.filter(this.data, function (d) {
                return d.dimension !== 'VOLTAGE';
            });
            filteredData.forEach(function (s, i) {
                let unitMap = Utils.unitMap.get(s.dimension == 'POWER' ? 'ENERGY' : 'VOLUME');
                zoomLayerData.push(s.key + ': ');
                zoomLayerData.push(Utils.formatRounded(s.total, 'k' + unitMap.unit, that.locale));
                var cost;
                if (s.seriesType === 'FEED' && s.totalReward != null) {
                    cost = Utils.formatRounded(s.totalCost + s.totalReward, '€', that.locale, 3) + ' (' + Utils.formatRounded(s.totalCost, '€', that.locale, 3) + ' + ' + Utils.formatRounded(s.totalReward, '€', that.locale, 3) + ' )';
                }
                else {
                    cost = Utils.formatRounded(s.totalCost, '€', that.locale, 3);
                }
                if (i < that.data.length - 1) {
                    cost = cost + ' | ';
                }
                if (that.configuration.tariff !== null && s.costKey) {
                    zoomLayerData.push(s.costKey + ': ');
                    zoomLayerData.push(cost);
                }
            });
            var y_pos = $('.nv-lineChart .nv-background rect').attr('height') - 10;
            var x_pos = 20;
            var resetButton_x_pos = $('.nv-lineChart .nv-background rect').attr('width') - 40;
            var distance = 20;
            d3.selectAll(".zoomSummaryLayer g").remove();
            var valuesLayer = d3.select(".zoomSummaryLayer").append('g');
            valuesLayer.append("svg:image").attr("x", resetButton_x_pos).attr("y", y_pos - 15).attr("width", 40).attr("height", 25)
                .attr("class", "resetZoom").attr("xlink:href", "/images/resetZoom.svg").style('cursor', 'pointer').style('z-index', '1000').attr('title', 'reset');
            $(".resetZoom").off();
            $(".resetZoom").click(function (e) {
                d3.select(".zoomSummaryLayer").attr("display", "none");
                that.isZoomActive = false;
                that.onResetZoomCallback(that.from, that.to);
            });
            valuesLayer.selectAll("text").remove();
            valuesLayer.selectAll("text").data(zoomLayerData).enter().append("text").text(function (d, i) {
                return d;
            }).attr("y", y_pos).style("font-weight", function (d, i) {
                return i % 2 != 0 ? "bold" : "normal";
            });
            valuesLayer.selectAll("text")
                .style("display", "block")
                .attr("x", function (d) {
                var c_pos = x_pos;
                x_pos = x_pos + this.getBBox().width + distance;
                return c_pos;
            });
            d3.select(".zoomSummaryLayer").attr("display", "block");
        }
    }
    addExportDataButton() {
        d3.select(this.chartElement + " .downloadIcon").remove();
        $(this.chartElement + " " + this.wrapCssClass).append($(".downloadSVG svg .downloadIcon").clone());
        d3.select(this.chartElement + " .downloadIcon").attr("transform", "translate(7, 3) scale(0.25)");
        let that = this;
        d3.select(this.chartElement + " .downloadIcon").on('mouseup', function () {
            $(".exportForm .container").val(that.configuration.containerId);
            $(".exportForm .from").val(that.from);
            $(".exportForm .to").val(that.to);
            $(".exportForm .type").val(that.data[0].dimension);
            $(".exportForm .granularity").val("FIFTEEN_MINUTELY");
            $(".exportForm").submit();
        });
    }
    addNavigationDial() {
        let that = this;
        d3.select(that.chartElement + " .dial").remove();
        $(that.chartElement + " " + that.wrapCssClass).append($(that.navigationElement).clone());
        d3.select(that.chartElement + " .dial").attr("transform", "translate(" + (-230.18673 + that.getWidth() - 70 - 10) + "," + (-228.77397 + 10) + ") scale(0.69306931)");
        var wrap = d3.select(that.chartElement + " " + that.wrapCssClass);
        wrap.select(".dial .up").select("title").text(i18next.t('zoomIn'));
        wrap.select(".dial .up").on("mouseup", function () {
            that.onNavigateChart(Dial.UP);
        }).on("touchend", function () {
            d3.event.stopPropagation();
            d3.event.preventDefault();
            that.onNavigateChart(Dial.UP);
        });
        wrap.select(".dial .down").select("title").text(i18next.t('zoomOut'));
        wrap.select(".dial .down").on("mouseup", function () {
            that.onNavigateChart(Dial.DOWN);
        }).on("touchend", function () {
            d3.event.stopPropagation();
            d3.event.preventDefault();
            that.onNavigateChart(Dial.DOWN);
        });
        wrap.select(".dial .left").select("title").text(i18next.t('moveLeft'));
        wrap.select(".dial .left").on("mouseup", function () {
            that.onNavigateChart(Dial.LEFT);
        }).on("touchend", function () {
            d3.event.stopPropagation();
            d3.event.preventDefault();
            that.onNavigateChart(Dial.LEFT);
        });
        wrap.select(".dial .right").select("title").text(i18next.t('moveRight'));
        wrap.select(".dial .right").on('mouseup', function () {
            that.onNavigateChart(Dial.RIGHT);
        }).on('touchend', function () {
            d3.event.stopPropagation();
            d3.event.preventDefault();
            that.onNavigateChart(Dial.RIGHT);
        });
        // Zoom to last 15 minutes
        wrap.select(".dial .center").select("title").text(i18next.t('switchToLive'));
        wrap.select(".dial .center").on('mouseup', function () {
            that.onNavigateChart(Dial.CENTER);
        }).on('touchend', function () {
            d3.event.stopPropagation();
            d3.event.preventDefault();
            that.onNavigateChart(Dial.CENTER);
        });
    }
    destroy() {
        d3.selectAll('.nvtooltip').remove();
        d3.selectAll(this.chartElement).selectAll('*').remove();
        if (this.resizeHandler) {
            this.resizeHandler.clear();
        }
    }
    setSeriesVisibility(key, visible) {
        this.seriesVisibility.set(key, visible);
        this.updateSeriesVisibility(this.data);
        this.chart.update();
    }
    getSeriesVisibility() {
        return this.seriesVisibility;
    }
    printData() {
        return this.data;
    }
}
var Dial;
(function (Dial) {
    Dial[Dial["LEFT"] = 'left'] = "LEFT";
    Dial[Dial["RIGHT"] = 'right'] = "RIGHT";
    Dial[Dial["UP"] = 'up'] = "UP";
    Dial[Dial["DOWN"] = 'down'] = "DOWN";
    Dial[Dial["CENTER"] = 'center'] = "CENTER";
})(Dial || (Dial = {}));
export { Chart, Dial };
