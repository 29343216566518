"use strict";
import Utils from "./utils";
var lastDataReceivedAt;
export default class LiveCycleLoop {
    constructor(url, initialTime, pollingInterval) {
        this.callbackStore = new buckets.Dictionary();
        this.token = 0;
        this.url = url;
        this.pollingInterval = pollingInterval;
        this.since = initialTime;
        this.run(this.token);
    }
    static getInstance(url, initialTime, pollingInterval) {
        if (LiveCycleLoop.instance == null || LiveCycleLoop.instance.isStateChanged(url, pollingInterval)) {
            if (LiveCycleLoop.instance != null) {
                LiveCycleLoop.instance.token++;
            }
            else {
                lastDataReceivedAt = initialTime;
            }
            LiveCycleLoop.instance = new LiveCycleLoop(url, initialTime, pollingInterval);
        }
        return LiveCycleLoop.instance;
    }
    isStateChanged(url, pollingInterval) {
        if (this.url !== url || this.pollingInterval !== pollingInterval) {
            return true;
        }
        return false;
    }
    run(token) {
        this.requestTime = new Date().getTime();
        let that = this;
        if (that.since < 0) {
            throw new TypeError("invalid since parameter:" + that.since);
        }
        Utils.getJSONAsync(that.url + '&wait=5000&since=' + that.since, function (resultIn) {
            let result = resultIn;
            //console.log("last at:" + new Date() + " result:" + (result !== null));
            that.responseTime = new Date().getTime();
            if (token === that.token) {
                let powerSeries = result != null ? result.filter(s => s.type === 'POWER' && s.values.length > 0) : null;
                if (powerSeries != null && powerSeries.length > 0) {
                    Utils.decompress(powerSeries);
                    that.since = Math.min(...(powerSeries.map(s => s.values[s.values.length - 1].time)));
                    lastDataReceivedAt = that.since;
                }
                that.callbackStore.values().forEach(function (callback) {
                    callback.call(this, result);
                });
                setTimeout(function () {
                    that.run(token);
                }, (that.pollingInterval - (that.responseTime - that.requestTime)));
            }
        });
    }
    getLastMeasurementTime() {
        return this.since;
    }
    registerFordata(callback) {
        let id = this.uniqueId();
        this.callbackStore.set(id, callback);
        return id;
    }
    ;
    unregister(id) {
        this.callbackStore.remove(id);
    }
    uniqueId() {
        return 'id-' + Math.random().toString(36).substr(2, 16);
    }
    ;
    static getLastMeasurementTimeStamp() {
        if (LiveCycleLoop.instance) {
            return LiveCycleLoop.instance.getLastMeasurementTime();
        }
        return Number.MAX_SAFE_INTEGER;
    }
    resetTo(time) {
        this.token++;
        this.since = time;
        this.run(this.token);
    }
}
export { lastDataReceivedAt };
