"use strict";
import DiscovergyBarChart from "./discovergyBarChart";
import * as moment from 'moment';
import { Dial } from "./chart";
export default class RLMBarChart extends DiscovergyBarChart {
    constructor(chartElement, configuration) {
        super(chartElement, configuration);
        this.chart.x(function (d) {
            return new Date(d.x);
        });
        var that = this;
        this.chart.xAxis.tickValues(function () {
            var tickValues = [];
            var hours = 60 * 60 * 1000;
            var increment = that.to - that.from > 30 * hours ? 12 * hours : 2 * hours;
            for (var i = that.from; i < that.to; i += increment) {
                tickValues.push(new Date(i));
            }
            return tickValues;
        }).tickFormat(function (d) {
            var test = moment(d).format('HH:mm');
            if (test == "00:00")
                return moment(d).format('DD MMM');
            return test;
        });
    }
    onNavigateChart(dial) {
        switch (dial) {
            case Dial.UP:
                this.transformFocusChart(0.5, 0.4, -0.4, 1);
                break;
            case Dial.DOWN:
                this.transformFocusChart(0.5, -0.4, 0.4, 3);
                break;
            case Dial.LEFT:
                this.transformFocusChart(0.5, -0.2, -0.2, 4);
                break;
            case Dial.RIGHT:
                this.transformFocusChart(0.5, 0.2, 0.2, 2);
                break;
        }
    }
    updateScopeLabel() {
        d3.select(this.wrapCssClass + " .scopeLabel").remove();
        d3.select(this.wrapCssClass).append('text').attr("text-anchor", "start").attr("class", "scopeLabel").attr('x', 38).attr('y', 24);
        var xDomain = this.chart.xAxis.domain();
        if ((xDomain[xDomain.length - 1].getTime() - xDomain[0].getTime()) < 2)
            return;
        // This is done to avoid counting midnight as the next day
        var startTime = new Date(xDomain[0].getTime() + 1);
        var endTime = new Date(xDomain[xDomain.length - 1].getTime() - 1);
        var startDay = this.locale.timeFormat("%e")(startTime);
        var endDay = this.locale.timeFormat("%e")(endTime);
        var startMonth = this.locale.timeFormat("%B")(startTime);
        var endMonth = this.locale.timeFormat("%B")(endTime);
        var startYear = this.locale.timeFormat("%Y")(startTime);
        var endYear = this.locale.timeFormat("%Y")(endTime);
        if (startDay == endDay && startMonth == endMonth && startYear == endYear) {
            var scopeText = startDay + ". " + startMonth + " " + startYear;
        }
        else if (startMonth == endMonth && startYear == endYear) {
            var scopeText = startDay + ". " + " – " + endDay + ". " + " " + startMonth + " " + startYear;
        }
        else if (startYear == endYear) {
            var scopeText = startDay + ". " + startMonth + " – " + endDay + ". " + endMonth + " " + startYear;
        }
        else {
            var scopeText = startDay + ". " + startMonth + " " + startYear + " – " + endDay + ". " + endMonth + " " + endYear;
        }
        d3.select(this.wrapCssClass + " .scopeLabel").text(scopeText);
    }
    transformFocusChart(anchor, leftFactor, rightFactor, dialButtonIndex) {
        var xDomain = this.chart.xAxis.domain();
        var currentFrom = xDomain[0];
        var currentTo = xDomain[xDomain.length - 1];
        if (currentFrom instanceof Date) {
            currentFrom = currentFrom.getTime();
        }
        if (currentTo instanceof Date) {
            currentTo = currentTo.getTime();
        }
        var difference1 = (currentTo - currentFrom) * anchor;
        var difference2 = (currentTo - currentFrom) * (1 - anchor);
        if (dialButtonIndex === 1 && ((currentTo + difference2 * rightFactor) - (currentFrom + difference1 * leftFactor)) < 60000)
            return;
        var limit = 604800000;
        //  if (showThreePhases)
        //  limit = 172800000;
        if (dialButtonIndex === 3 && ((currentTo + difference2 * rightFactor) - (currentFrom + difference1 * leftFactor)) > limit)
            return;
        let from = Math.trunc(currentFrom + difference1 * leftFactor);
        let to = Math.trunc(currentTo + difference2 * rightFactor);
        console.log('transform ' + new Date(from) + "-" + new Date(to));
        this.onNavigateCallback(from, to);
    }
}
