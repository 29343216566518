<style>

  .flowchart {
    display: inline-block;
    vertical-align: bottom;
    margin: 10px 10px 10px 10px;
    line-height: 0;
  }

  .flowchart tr,
  .flowchart td {
    padding: 0;
    margin: 0;
  }

  .flowchart .grid {
    padding-top: 6px;
  }

  .flowchart .house {
    height: 40px;
  }

  .flowchart .number {
    height: 40px;
    font-family: 'Roboto';
    font-size: 36px;
    text-align: center;
    white-space: nowrap;

  }

  .livePrice.number {
    font-family: sans-serif;
    font-size: 36px;
    text-align: center;
    white-space: nowrap;
  }

  .flowchart .number span {
    display: block;
  }

  .flowchart .arrowNumber {
    color: #ffffff;
    background-color: #808080;
    padding-left: 6px;
    padding-right: 6px;
  }

  .flowchart .houseNumber {
    color: #608000;
  }

  .flowchart img {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .flowchart .padding {
    width: 20px;
  }

  .flowchart .arrowPadding {
    padding-top: 15px;
  }

  .flowchart.arrow1Forward .arrow1 .backward {
    display: none;
  }

  .flowchart.arrow1Backward .arrow1 .forward {
    display: none;
  }

  .flowchart.arrow2Forward .arrow2 .backward {
    display: none;
  }

  .flowchart.arrow2Backward .arrow2 .forward {
    display: none;
  }

  .flowchart.sunHidden .sunPart {
    display: none;
  }

  .flowchart.gridHidden .gridPart {
    display: none;
  }

  .flowchart.houseHidden .housePart {
    display: none;
  }

  .flowchart .livePrice {
    /*background-color: #808080;*/
    padding-left: 15px;
    padding-right: 6px;
  }

  .flowchart .threePhaseLiveValues {
    color: rgba(70, 70, 70, 0.39);
  }

  .flowchart .threePhaseLiveValues span {
    display: inline;
  }

  .phase1 {
    color: #c0392b;
  }

  .phase2 {
    color: #27ae60;
  }

  .phase3 {
    color: #2980b9;
  }

</style>

<template>

  <div class="flowGraphic" style="display:inline-block">

    <table class="flowchart arrow1Forward  introJ introFlowGraphic" cellspacing="0" cellpadding="0"
           v-show="!meterDisconnected"
           v-bind:class="[{arrow1Forward: production!=null},
                          {sunHidden:production==null},
                          {houseHidden:(consumption==null && (configuration != null && (configuration.solution !=='BIDIRECTIONAL' && configuration.originalSolution!==2 && !isTwoTariffBidirectional)))},
                          {gridHidden:consumption_from_grid==null && feed==null},
                          consumption_from_grid>Math.abs(feed)? 'arrow2Backward':'arrow2Forward']">
      <tr style="min-height: 102px">
        <td class="sun sunPart" rowspan="3"><img id="energySrc"
                                                 :src="'/flowchart/' + (configuration == null?'photovoltaics.png':configuration.producerType.toLowerCase()+'.png')"
                                                 height="63px" style="display: block">
        </td>
        <td class="sunPart" rowspan="3">
          <div class="padding"></div>
        </td>
        <td class="sunPart arrowPadding" colspan="3"></td>
        <td class="sunPart" rowspan="3">
          <div class="padding"></div>
        </td>
        <td class="roof housePart" style="text-align: center"><span class="disco-house_roof" v-bind:style="{color:house_icon_color, fontSize:'27px'}"></span>
        </td>
        <td class="gridPart arrowPadding" rowspan="3">
          <div class="padding"></div>
        </td>
        <td class="gridPart" colspan="3"></td>
        <td class="gridPart" rowspan="3">
          <div class="padding"></div>
        </td>
        <td class="grid" rowspan="3" v-show="feed!==null || production!==null"><span class="disco-transmission-tower" style="font-size: 64px;"></span></td>
      </tr>
      <tr>
        <td class="arrow1 sunPart"><img class="forward" src="/flowchart/tail_forward.png"><img class="backward" src="/flowchart/head_backward.png"></td>
        <td class="arrow1 number arrowNumber sunPart">
          <span>{{production}}{{ (configuration != null && configuration.solution == 'MICROGRID_CONSUMER') || convertToMicrogrid ? '%' : ''}}</span>
        </td>
        <td class="arrow1 sunPart"><img class="forward" src="/flowchart/head_forward.png"><img class="backward" src="/flowchart/tail_backward.png"></td>
        <td class="house number  housePart houseNumber"><span v-bind:style="{color:consumption_color}">{{consumption}}</span>
          <span v-bind:style="{color:house_icon_color}"
                class="house_part_img disco-grid"
                v-if="configuration != null && (configuration.solution==='BIDIRECTIONAL' || configuration.originalSolution===2 || isTwoTariffBidirectional) && solution!==4"></span>
        </td>
        <td class="house number housePart threePhaseLiveValues" v-if="phase1||phase2||phase3"><span style="margin-left: 10px">( </span>
          <span class="phase1" v-bind:style="{color:phase1_color}">{{phase1}}</span> <span v-show="phase2>=0">+</span>
          <span class="phase2" v-bind:style="{color:phase2_color}">&nbsp;{{phase2}}</span> <span v-show="phase3>=0">+</span>
          <span class="phase3" v-bind:style="{color:phase3_color}">&nbsp;{{phase3}}</span> )
        </td>
        <td class="arrow2 gridPart"><img class="forward" src="/flowchart/tail_forward.png"><img class="backward" src="/flowchart/head_backward.png">
        </td>
        <td class="arrow2 number arrowNumber gridPart">
          <span>{{feedOrConsumption}}{{ (configuration != null && configuration.solution == 'MICROGRID_CONSUMER') || convertToMicrogrid ? '%' : ''}}</span>
        </td>
        <td class="arrow2 gridPart"><img class="forward" src="/flowchart/head_forward.png"><img class="backward" src="/flowchart/tail_backward.png">
        </td>
        <td class="gridPart" rowspan="3">
          <div class="padding"></div>
        </td>
        <td class="arrow2 number livePrice" v-if="netPrice"><span v-bind:style="{color:netPrice_color}" style="white-space: nowrap;">{{netPrice}}</span></td>

        <td class="arrow2 number" v-if="batteryEnergy" style="padding-left: 25px;font-size:medium">
          <span class="fa-stack fa-1x">
            <span class="fa-stack fa-2x" style="margin-top:-33px">
              <i class="fa fa-battery-full fa-stack-2x"></i>
              <i class="fa fa-stack-1x fa-inverse" style="font-size:80%; padding-left:8px">{{batteryEnergy}}%</i>
            </span>
          </span>
        </td>
        <td rowspan="3" v-show="!meterDisconnected && configuration != null">
          <div style="margin-top: -15px; padding-left: 10px">
            <div id="currentWeatherIcon" style="text-align: center; font-family: 'discovergy-portal'; font-size: 3.5em; line-height: 1;"></div>
            <div id="currentWeatherText" style="text-align: center; font-size: 1.5em; line-height: 1; font-weight: bold;"></div>
          </div>
        </td>
      </tr>
      <tr>
        <td class="sunPart "></td>
        <td class="sunPart arrowPadding" colspan="3"></td>
        <td class="floor housePart" style="padding: 0px 10px">
          <div v-bind:style="{color:house_icon_color, borderTop:'solid', width:'60px', margin:'auto'}"></div>
        </td>
        <td class="gridPart arrowPadding" colspan="3"></td>
      </tr>
    </table>

    <table style="width: 100%" cellpadding="3" cellspacing="0" v-show="meterDisconnected" class="meter-disconnected-block">
      <tr>
        <td><h1 style="padding-left: 20px; font-size: 30px; font-weight: bold;">{{getTranslatedText('meterDisconnected')}}</h1></td>
      </tr>
      <tr>
        <td style="padding-left: 20px"><span class="subMessage"> {{getTranslatedText('lastDataReceivedAt')}}: <span
          class="lastTS">{{getFormattedTimeStamp(lastDataTimeStamp)}}</span>
									</span></td>
      </tr>
    </table>

  </div>

</template>

<script>
  import Utils from '../utils';

  export default {
    name: 'FlowGraphic',
    props: ['configuration', 'liveCycleHook', 'measurementType', 'solution', 'convertToMicrogrid'],
    data() {
      return this.initialState();
    },
    mounted() {
      this.registerHandler();
    },

    methods: {
      initialState() {
        var initialData = {
          consumption: null,
          consumption_color: this.configuration ? this.configuration.colors.get('consumption') : '#008739',
          production: null,
          production_color: '#008739',

          house_icon_color: this.configuration ? this.configuration.colors.get('house_icon') : '#FFFFFF',
          consumption_from_grid: null,
          feed: null,
          selfConsumption: null,

          feedOrConsumption: null,

          netPrice: null,
          netPrice_color: null,

          phase1: null,
          phase1_color: null,
          phase2: null,
          phase2_color: null,
          phase3: null,
          phase3_color: null,

          meterDisconnected: false,
          lastDataTimeStamp: 0,

          batteryEnergy: null,
          isTwoTariffBidirectional: false

        }

        console.log("config: " + this.configuration);
        if (this.configuration) {
          initialData.lastDataTimeStamp = this.configuration.upperBound;
          initialData.meterDisconnected = this.configuration.meterDisconnected;
        }

        return initialData;
      },

      getFormattedTimeStamp(timeStamp) {
        return moment(timeStamp).format('DD.MM.YYYY HH:mm:ss');
      },


      registerHandler() {
        let that = this;
        let SCALING_THRESHOLD = 100000;
        let liveCycle = this.liveCycleHook;
        if (liveCycle) {

          liveCycle.registerFordata(function (data) {
              let dataSeries = Utils.mapToDataSeries(data);

              if (dataSeries !== null) {
                dataSeries = dataSeries.filter(series => series.dimension === (that.measurementType === 'ELECTRICITY' || that.measurementType === 'HEATING' ? 'POWER' : 'VOLUME_FLOW'));
              }

              if (dataSeries == null || dataSeries.length === 0) {
                if (new Date().getTime() - that.lastDataTimeStamp > that.configuration.maxWaitingTime) {
                  that.meterDisconnected = true;
                }
                return;
              }

              if (that.convertToMicrogrid) {
                dataSeries = Utils.convertPVSeriesToMicroGrid(dataSeries, that.configuration.producerKey, that.configuration.feedInTariff);
              }


              let batteryEnergySeries = _.find(dataSeries, function (ds) {
                if (ds.extras && ds.extras.batteryEnergy) {
                  return true;
                }
              });

              if (batteryEnergySeries) {
                that.batteryEnergy = batteryEnergySeries.extras.batteryEnergy;
              }

              let consumptionData = Utils.getLastValue(Utils.getSeries(dataSeries, 'CONSUMPTION', (that.measurementType === 'ELECTRICITY' || that.measurementType === 'HEATING') ? 'POWER' : 'VOLUME_FLOW'));

              if ((that.configuration.solution === 'BIDIRECTIONAL' || that.configuration.originalSolution === 2) && consumptionData) {
                that.consumption_from_grid = consumptionData.value;

              } else if (consumptionData) {
                var house = consumptionData.value;
                var title = house + ' W';
                if (that.measurementType == 'GAS' || that.measurementType == 'WATER') {
                  house = Utils.getCurrentD3Locale().numberFormat(',.1f')(house) + ' m³/h';
                  title = house;
                } else if (consumptionData.value >= SCALING_THRESHOLD) {
                  house = that.getScaledValue(consumptionData.value);
                  title = house;
                }
                that.consumption = house;
                that.consumption_color = consumptionData.color;

                document.title = title + ' – Verbrauch';
              }

              //TODO send preprocessed data from server
              let productionData = Utils.getLastValue(Utils.getSeries(dataSeries, that.configuration.solution === 'PRODUCTION_CONSUMPTION' ? 'FEED' : 'PRODUCTION', 'POWER'));
              let selfConsumptionData = Utils.getLastValue(Utils.getSeries(dataSeries, 'OWN_CONSUMPTION'/**TODO be consistent with the naming in server**/, 'POWER'));

              let feedData = Utils.getLastValue(Utils.getSeries(dataSeries, 'FEED', 'POWER'));
              var unscaledFeed = 0;
              if (feedData) {

                var feed = Math.abs(feedData.value);
                unscaledFeed = feed;


                that.feed = that.getScaledValue(feed);

                if (that.configuration.solution === 'BIDIRECTIONAL' || that.configuration.originalSolution === 2) {
                  document.title = that.consumption_from_grid > Math.abs(feed) ? that.consumption_from_grid + ' W - Verbrauch' : that.feed + ' - Einspeisung'
                }
              }

              if (productionData) {
                that.production = productionData.value;
                that.production_color = productionData.color;
              }


              if (selfConsumptionData) {
                that.selfConsumption = selfConsumptionData.value;

                if (consumptionData) {
                  that.consumption_from_grid = consumptionData.value - selfConsumptionData.value;
                }
                if (productionData) {
                  that.feed = productionData.value - selfConsumptionData.value;
                  unscaledFeed = that.feed;
                }

              }


              let phase1Value = Utils.getLastValue(Utils.getSeries(dataSeries, 'CONSUMPTION_PHASE1', 'POWER'));
              let phase2Value = Utils.getLastValue(Utils.getSeries(dataSeries, 'CONSUMPTION_PHASE2', 'POWER'));
              let phase3Value = Utils.getLastValue(Utils.getSeries(dataSeries, 'CONSUMPTION_PHASE3', 'POWER'));

              if (phase1Value && phase2Value && phase3Value) {
                var consumption = phase1Value.value + phase2Value.value + phase3Value.value;


                that.phase1 = that.getScaledValue(phase1Value.value, consumption);
                that.phase1_color = phase1Value.color;

                that.phase2 = that.getScaledValue(phase2Value.value, consumption);
                that.phase2_color = phase2Value.color;

                that.phase3 = that.getScaledValue(phase3Value.value, consumption);
                ;
                that.phase3_color = phase3Value.color;

                that.consumption = that.getScaledValue(consumption);
                document.title = that.consumption + ' – Verbrauch';
              }

              if ((that.solution == 7 || that.solution == 11 || that.configuration.originalSolution == 7 || that.configuration.solution === 'TWO_TARIFF') && consumptionData != null) {
                let houseValue_tariff2 = Utils.getLastValue(Utils.getSeries(dataSeries, 'CONSUMPTION_TARIFF2', that.measurementType === 'ELECTRICITY' || that.measurementType === 'HEATING' ? 'POWER' : 'VOLUME_FLOW'));

                if (houseValue_tariff2 && that.configuration.tariff != null) {
                  if (consumptionData.value >= houseValue_tariff2.value) {
                    that.netPrice = Utils.formatRounded(that.configuration.tariff.pricePerKWh * 100, 'ct/kWh', Utils.getCurrentD3Locale(), 2);
                    that.netPrice_color = that.consumption_color;
                  } else {
                    that.netPrice = Utils.formatRounded(that.configuration.tariff.secondaryPricePerKWh * 100, 'ct/kWh', Utils.getCurrentD3Locale(), 2);
                    that.netPrice_color = houseValue_tariff2.color;
                  }
                  that.netPrice = " " + that.netPrice;
                }

                that.consumption = Math.max(consumptionData.value, houseValue_tariff2 ? houseValue_tariff2.value : 0);
                if (houseValue_tariff2 && houseValue_tariff2.value > consumptionData.value) {
                  that.house_icon_color = houseValue_tariff2.color;
                  that.consumption_color = that.house_icon_color;
                } else {
                  that.house_icon_color = that.configuration.houseIconColor;
                }

                let feed_tariff1 = Utils.getLastValue(_.find(dataSeries, function (s) {
                  return s.seriesType == 'FEED_TARIFF1';
                }));

                let feed_tariff2 = Utils.getLastValue(_.find(dataSeries, function (s) {
                  return s.seriesType == 'FEED_TARIFF2';
                }));

                if (feed_tariff1 && feed_tariff2) {
                  that.feed = Math.max(feed_tariff1.value, feed_tariff2.value);
                } else if (feed_tariff1) {
                  that.feed = feed_tariff1.value;
                } else if (feed_tariff2) {
                  that.feed = feed_tariff2.value;
                }

                if (that.feed != null) {
                  that.isTwoTariffBidirectional = true;
                  that.consumption_from_grid = that.consumption;
                  that.consumption = null;
                  document.title = that.feed + ' – Einspeisung';
                } else {
                  document.title = that.consumption + ' – Verbrauch';
                }

              }

              if (that.configuration.solution == 'MICROGRID_CONSUMER' || that.convertToMicrogrid) {
                let microgridData = Utils.getMicrogridData(dataSeries, that.configuration.tariff, that.configuration.feedInTariff, that.convertToMicrogrid, null);
                console.log("microgridData:");
                console.log(microgridData);
                if (microgridData) {
                  that.feed = 0;
                  that.consumption = Utils.getRounded(microgridData.gridValue + microgridData.producerValue);
                  that.production = microgridData.producerPercentage;
                  that.consumption_from_grid = microgridData.gridPercentage;

                  if ((that.configuration.originalSolution === 3/*TODO replace this hardcoded value*/ || that.configuration.feedInTariff) && that.configuration.tariff) {
                    that.netPrice = Utils.formatRounded(microgridData.netPrice, 'ct/kWh', Utils.getCurrentD3Locale(), 2);
                    that.netPrice_color = microgridData.netPriceColor;
                  }
                }
              }

              if (that.feed !== undefined || that.consumption_from_grid !== undefined) {
                that.feedOrConsumption = unscaledFeed > that.consumption_from_grid ? that.feed : that.consumption_from_grid;
              }


              let d = _.find(dataSeries, function (s) {
                return s.dimension === that.measurementType === 'ELECTRICITY' || that.measurementType === 'HEATING' ? 'POWER' : 'VOLUME_FLOW';
              });

              if (d && d.values[d.values.length - 1].x > that.lastDataTimeStamp) {
                that.meterDisconnected = false;
                that.lastDataTimeStamp = d.values[d.values.length - 1].x;

                // console.log('meterReconnected:' + new Date(that.lastDataTimeStamp));
              }


            }
          )
          ;
        }
      },
      resetFields() {
        let initialData = this.initialState();
        // Iterate through the props
        for (let prop in initialData) {
          // Reset the prop locally.
          this[prop] = initialData[prop];
        }
        console.log("lastDataTS: " + this.lastDataTimeStamp);
      },

      getScaledValue(value, referenceValue = null) {
        let SCALING_THRESHOLD = 100000;
        referenceValue = Math.max(Math.abs(value), Math.abs(referenceValue));
        if (referenceValue >= SCALING_THRESHOLD) {
          var prefix = d3.formatPrefix(referenceValue);
          return (d3.format('.1f')(prefix.scale(value)) + ' ' + prefix.symbol + 'W').replace('.', ',');
        }
        return value;
      },

      getTranslatedText(key) {
        return i18next.t(key);
      }
    },

    watch: {

      liveCycleHook: {
        handler: function (val, oldVal) {
          this.resetFields();

          if (val != null) {
            this.registerHandler();
          }
        }
      },

      solution: {
        handler: function (val, oldVal) {
          this.resetFields();
        }
      }
    }
  }

</script>
