"use strict";
import Utils from "./utils";
export default class Configuration {
    constructor() {
        this.navigationElement = false;
        this.producerType = 'PHOTOVOLTAICS';
        this.producerKey = 'Sonne';
        this.convertToMicrogrid = false;
        this.showReactiveCounters = false;
        this.maxWaitingTime = 86400000;
        this.meterDisconnected = false;
        this.colors = buckets.Dictionary();
    }
    static getModifiedConfiguration(c, targetSolution) {
        let configuration = Object.create(c);
        configuration.convertToMicrogrid = false;
        if (targetSolution == Solution.THREE_PHASE) {
            configuration.flowGraphicDataSource = c.constructUrl('getThreePhasesLast');
            configuration.focusChartDataSource = c.constructUrl('getThreePhasePower');
            configuration.contextChartDataSource = c.constructUrl('getThreePhaseConsumption');
            /*if (configuration.isAdminSession) {
              configuration.contextChartDataSource = c.constructUrl('getThreePhaseConsumption');
            } else {
              configuration.contextChartDataSource = c.constructUrl('getConsumption');
            }*/
        }
        else if (targetSolution == Solution.DISAGGREGATION) {
            // configuration.focusChartDataSource = c.constructUrl('getDisaggregationResults');
            configuration.focusChartDataSource = c.constructUrl('getDisaggregatedPower');
            configuration.contextChartDataSource = c.constructUrl('getDisaggregatedEnergy');
            configuration.flowGraphicDataSource = c.constructUrl('getDisaggregatedLast');
        }
        else if (targetSolution == Solution.LOAD_PROFILE) {
            configuration.focusChartDataSource = configuration.constructUrl('getSemanticLoadProfile');
            configuration.contextChartDataSource = configuration.constructUrl('getSemanticLoadProfile');
        }
        else if (targetSolution == Solution.MARKET_PRICES) {
            configuration.focusChartDataSource = c.constructUrl('getPower');
            configuration.contextChartDataSource = c.constructUrl('getConsumption');
        }
        else {
            if (targetSolution == Solution.MICROGRID_CONSUMER && configuration.originalSolution == Solution.PRODUCTION_CONSUMPTION) {
                configuration.convertToMicrogrid = true;
            }
            configuration.focusChartDataSource = c.constructUrl('getPower');
            configuration.contextChartDataSource = c.constructUrl('getConsumption');
            configuration.flowGraphicDataSource = c.constructUrl('getLast');
        }
        configuration.solution = Solution[targetSolution];
        return configuration;
    }
    getResourcePath() {
        if (this.type == 'WIDGET' && this.tokenId !== null) {
            return '/json/WidgetJson.';
        }
        return '/json/PortalJSON.';
    }
    constructUrl(resourceName) {
        var url = this.getResourcePath();
        url = url + resourceName + '?';
        if (resourceName === 'getContainer' || this.type == 'PORTAL' || this.tokenId === null) {
            url = url + 'containerId=' + this.containerId;
        }
        if (this.type == 'WIDGET' && this.tokenId !== null) {
            url = url + '&tokenId=' + this.tokenId;
        }
        return Utils.SERVER + url;
    }
}
//Dont chnage the order of enums, add the new values at the end
var Solution;
(function (Solution) {
    Solution[Solution["STANDARD"] = 0] = "STANDARD";
    Solution[Solution["STANDARD_GAS"] = 1] = "STANDARD_GAS";
    Solution[Solution["BIDIRECTIONAL"] = 2] = "BIDIRECTIONAL";
    Solution[Solution["MICROGRID_CONSUMER"] = 3] = "MICROGRID_CONSUMER";
    Solution[Solution["THREE_PHASE"] = 4] = "THREE_PHASE";
    Solution[Solution["HAPPY_HOUR"] = 5] = "HAPPY_HOUR";
    Solution[Solution["PRODUCTION_CONSUMPTION"] = 6] = "PRODUCTION_CONSUMPTION";
    Solution[Solution["TWO_TARIFF"] = 7] = "TWO_TARIFF";
    Solution[Solution["PRODUCTION"] = 8] = "PRODUCTION";
    Solution[Solution["DISAGGREGATION"] = 9] = "DISAGGREGATION";
    Solution[Solution["MARKET_PRICES"] = 10] = "MARKET_PRICES";
    Solution[Solution["LOAD_PROFILE"] = 11] = "LOAD_PROFILE";
    Solution[Solution["STANDARD_WATER"] = 12] = "STANDARD_WATER";
    Solution[Solution["STANDARD_HEAT"] = 13] = "STANDARD_HEAT";
})(Solution || (Solution = {}));
export { Solution, Configuration };
