"use strict";
export default class Utils {
    static initialize() {
        Utils.unitMap.set('ENERGY', { unit: 'Wh', factor: Math.pow(10, -7) });
        Utils.unitMap.set('POWER', { unit: 'W', factor: Math.pow(10, -3) });
        Utils.unitMap.set('VOLUME', { unit: 'm³', factor: Math.pow(10, -3) });
        Utils.unitMap.set('VOLUME_FLOW', { unit: 'm³/h', factor: Math.pow(10, -3) });
        Utils.GERMAN_LOCALE = d3.locale({
            "decimal": ",",
            "thousands": "\u202F",
            "grouping": [3],
            "currency": ["€", ""],
            "dateTime": "%-d. %b %Y, %X",
            "date": "%-d. %B %Y",
            "time": "%H:%M:%S",
            "periods": ["", ""],
            "days": ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"],
            "shortDays": ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
            "months": ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"],
            "shortMonths": ["Jan", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"]
        });
        Utils.GERMAN_WEATHER_DESCRIPTIONS = {
            "clear-day": "klar",
            "clear-night": "klar",
            "rain": "Regen",
            "snow": "Schnee",
            "sleet": "Graupel",
            "wind": "Wind",
            "fog": "Nebel",
            "cloudy": "bewölkt",
            "partly-cloudy-day": "teils bewölkt",
            "partly-cloudy-night": "teils bewölkt"
        };
        Utils.ENGLISH_LOCALE = d3.locale({
            "decimal": ".",
            "thousands": ",",
            "grouping": [3],
            "currency": ["$", ""],
            "dateTime": "%-d %b %Y, %X",
            "date": "%-d %B %Y",
            "time": "%H:%M:%S",
            "periods": ["AM", "PM"],
            "days": ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
            "shortDays": ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
            "months": ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
            "shortMonths": ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
        });
        Utils.PORTUGUESE_LOCALE = d3.locale({
            "decimal": ",",
            "thousands": ".",
            "grouping": [3],
            "currency": ["R$", ""],
            "dateTime": "%-d %b %Y, %X",
            "date": "%-d %B %Y",
            "time": "%H:%M:%S",
            "periods": [],
            "days": ["Domingo", "Segunda-feira", "Terça-feira", "Quarta-feira", "Quinta-feira", "Sexta-feira", "Sábado"],
            "shortDays": ["Dom", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sab."],
            "months": ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
            "shortMonths": ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"]
        });
        Utils.WEATHER_ICON_COLORS = {
            "clear-day": "#000",
            "clear-night": "#000",
            "rain": "#000",
            "snow": "#000",
            "sleet": "#000",
            "wind": "#000",
            "fog": "#000",
            "cloudy": "#000",
            "partly-cloudy-day": "#000",
            "partly-cloudy-night": "#000"
        };
    }
    static test() {
        console.log('HELLLOO from Utils.ts');
        $('.msg').css('color', 'red');
    }
    static getJSONAsync(url, resultCallback) {
        // Base URL; we do not want to allow two calls with this URL to interleave
        var callId = url.split("?")[0];
        //url = remote_origin + url;
        var t = new Date();
        //console.log(t.getHours() + ":" + t.getMinutes() + ":" + t.getSeconds() + ":" + url);
        $.ajax({
            type: 'GET',
            url: url,
            dataType: 'json',
            cache: false,
            data: {},
            success: function (json_data) {
                if (json_data.status == "ok") {
                    //  assert.property(json_data, "result");
                    resultCallback(json_data.result);
                }
                else {
                    let reason = json_data.reason;
                    console.error("e", "JSON Error:" + reason);
                    if ((reason === "Illegal access to PortalJSON") || (reason === "Invalid permissions"))
                        window.location.assign("/login");
                }
            },
            error: function () {
                // This breaks Firefox!
                // onJSONError();
            }
        });
    }
    static getData(dataSource, parameters, resultCallback) {
        let from = parameters.get('from');
        let to = parameters.get('to');
        try {
            if (isNaN(from) || isNaN(to) || parseInt(from) <= 0 || parseInt(to) <= 0 || parseInt(from) > parseInt(to)) {
                console.error('Invalid from or to :' + Utils.toUrl(dataSource, parameters));
                return;
            }
            Utils.getJSONAsync(Utils.toUrl(dataSource, parameters), resultCallback);
        }
        catch (e) {
            console.error(e);
        }
    }
    static toUrl(dataSource, parameters) {
        var url = dataSource + (dataSource.indexOf('?') == -1 ? '?' : '');
        parameters.forEach((key) => {
            url = url + "&" + key + "=" + parameters.get(key);
        });
        return url;
    }
    //TODO: use inbuilt filter functionality
    static getSeries(data, seriesType, dimension) {
        if (data == null) {
            return undefined;
        }
        let result;
        for (var i = 0; i < data.length; i++) {
            if (data[i].seriesType == seriesType && data[i].dimension == dimension) {
                result = data[i];
            }
        }
        return result;
    }
    static mapToDataSeries(data) {
        if (data == null) {
            return data;
        }
        let result = new Array();
        //TODO send them in series from server
        var extras;
        for (let d of data) {
            if (d.extras) {
                extras = d.extras;
            }
            let values = new Array();
            for (let v of d.values) {
                values.push({
                    ...v,
                    x: v.time,
                    y: v.value,
                });
            }
            /*if (d.seriesType === 'CONSUMPTION_HOURLY') {
              for (var i = 0; i < values.length; i++) {
                values[i].x = moment(values[i].x).clone().startOf('day').valueOf();
              }
      
            }*/
            result.push({
                values: values,
                key: d.seriesKey,
                costKey: d.costKey,
                color: d.color,
                dimension: d.type,
                seriesType: d.seriesType,
                area: d.fillArea === null ? true : d.fillArea,
                meterSerialNumber: d.meterSerialNumber,
                total: d.total,
                totalCost: d.cost,
                extras: d.extras,
                disabled: d.type === 'VOLTAGE' ? true : false,
                sortIndex: d.sortIndex,
                yAxis: d.type === 'VOLTAGE' ? 2 : 1,
                strokeWidth: d.type === 'VOLTAGE' ? 1.8 : 1.5,
                classed: d.type === 'VOLTAGE' ? 'dashed' : d.seriesType != null && d.seriesType.indexOf('BIDGELY') !== -1 ? ' bidgely-series' : '',
            });
        }
        if (extras) {
            if (extras.totalConsumption != null) {
                let s = _.find(result, function (series) {
                    return series.seriesType == 'CONSUMPTION';
                });
                if (s !== null && s !== undefined) {
                    s.total = extras.totalConsumption;
                    s.totalCost = extras.consumptionCost;
                }
            }
            if (extras.totalHappyHourConsumption != null) {
                let s = _.find(result, function (series) {
                    return series.seriesType == 'CONSUMPTION_HAPPYHOUR';
                });
                s.total = extras.totalHappyHourConsumption;
                s.totalCost = extras.happyHourConsumptionCost;
            }
            if (extras.totalConsumptionPhase1 != null) {
                let s = _.find(result, function (series) {
                    return series.seriesType == 'CONSUMPTION_PHASE1';
                });
                s.total = extras.totalConsumptionPhase1;
                s.totalCost = extras.totalCostPhase1;
            }
            if (extras.totalConsumptionPhase2 != null) {
                let s = _.find(result, function (series) {
                    return series.seriesType == 'CONSUMPTION_PHASE2';
                });
                s.total = extras.totalConsumptionPhase2;
                s.totalCost = extras.totalCostPhase2;
            }
            if (extras.totalConsumptionPhase3 != null) {
                let s = _.find(result, function (series) {
                    return series.seriesType == 'CONSUMPTION_PHASE3';
                });
                s.total = extras.totalConsumptionPhase3;
                s.totalCost = extras.totalCostPhase3;
            }
            if (extras.totalConsumptionFromProducers != null) {
                let s = _.find(result, function (series) {
                    return series.seriesType == 'CONSUMPTION_FROM_PRODUCER';
                });
                s.total = extras.totalConsumptionFromProducers;
                s.totalCost = extras.consumptionCostFromProducers;
            }
            if (extras.totalConsumptionFromGrid != null) {
                let s = _.find(result, function (series) {
                    return series.seriesType == 'CONSUMPTION_COLLECTIVE';
                });
                s.total = extras.totalConsumptionFromGrid;
                s.totalCost = extras.consumptionCost;
            }
            if (extras.totalSelfConsumption != null) {
                let s = _.find(result, function (series) {
                    return series.seriesType == 'OWN_CONSUMPTION';
                });
                s.total = extras.totalSelfConsumption;
                s.totalCost = extras.savings;
            }
            if (extras.totalFeed != null) {
                let s = _.find(result, function (series) {
                    return series.seriesType == 'FEED' || series.seriesType == 'PRODUCTION';
                });
                s.total = extras.totalFeed;
                s.totalCost = extras.feedReward;
                s.totalReward = extras.selfConsumptionReward;
            }
            if (extras.totalMainConsumption != null) {
                let s = _.find(result, function (series) {
                    return series.seriesType == 'CONSUMPTION';
                });
                s.total = extras.totalMainConsumption;
                s.totalCost = extras.consumptionCostMain;
            }
            if (extras.totalSecondaryConsumption != null) {
                let s = _.find(result, function (series) {
                    return series.seriesType == 'CONSUMPTION_TARIFF2';
                });
                s.total = extras.totalSecondaryConsumption;
                s.totalCost = extras.consumptionCostSecondary;
            }
            if (extras.totalGasConsumption != null) {
                let s = _.find(result, function (series) {
                    return series.seriesType == 'CONSUMPTION' && series.dimension == 'VOLUME_FLOW';
                });
                if (s) {
                    s.total = extras.totalGasConsumption;
                }
            }
        }
        return result;
    }
    static getBasePrice(tariff, scope, timeRef) {
        var basePrice = 0;
        switch (scope) {
            case Scope.DAY:
                basePrice = tariff.monthlyBasePrice / timeRef.clone().daysInMonth();
                break;
            case Scope.MONTH:
                basePrice = tariff.monthlyBasePrice;
                break;
            case Scope.YEAR:
                basePrice = tariff.monthlyBasePrice * 12;
                break;
        }
        return basePrice;
    }
    static getStyledSumOfCosts(basePrice, locale, ...values) {
        var totalCost = (basePrice ? basePrice : 0);
        values.forEach(function (v) {
            totalCost += v.cost;
        });
        var costString = '';
        values.forEach(function (v, i) {
            costString += '<span style=color:' + v.color + ' > ' + Utils.formatRounded(v.cost, v.symbol ? v.symbol : '€', locale, 2) + '</span>';
            if (i < values.length - 1) {
                costString += ' + ';
            }
        });
        var result = Utils.formatRounded(totalCost, '€', locale, 2) + ' (';
        if (basePrice) {
            result += Utils.formatRounded(basePrice, '€', locale, 2) + ' + ';
        }
        result += costString + ') ';
        return result;
    }
    static getRounded(value) {
        var roundedValue;
        if (Math.abs(value) < 0.001) {
            roundedValue = Math.round(value * 10000) / 10000;
        }
        else if (Math.abs(value) < 1) {
            roundedValue = Math.round(value * 1000) / 1000;
        }
        else if (Math.abs(value) < 10) {
            roundedValue = Math.round(value * 100) / 100;
        }
        else if (Math.abs(value) < 100) {
            roundedValue = Math.round(value * 10) / 10;
        }
        else {
            roundedValue = Math.round(value);
        }
        return roundedValue;
    }
    static formatRounded(value, unit, locale, precision) {
        var formattedValue;
        if (unit && unit !== 'W' && unit !== 'kWh' && unit !== 'V') {
            formattedValue = Math.round(value * 1000) / 1000;
        }
        else {
            formattedValue = Utils.getRounded(value);
        }
        if (locale) {
            if (value == 0) {
                precision = 0;
            }
            formattedValue = locale.numberFormat(",." + precision + "f")(formattedValue);
        }
        if (unit == null)
            return formattedValue;
        return formattedValue + " " + unit;
    }
    static getLocalizaedScaledNumber(value, unit, scale) {
        let roundedValue = Utils.getRounded(value);
    }
    static filterData(data, measurementType) {
        var filteredData = data;
        if (measurementType == 'GAS') {
            filteredData = data.filter(function (s) {
                return s.dimension == 'VOLUME' || s.dimension == 'VOLUME_FLOW';
            });
        }
        else {
            filteredData = data.filter(function (s) {
                return s.dimension == 'ENERGY' || s.dimension == 'POWER';
            });
        }
        return filteredData;
    }
    static getSeriesMax(data) {
        var max;
        var maxArray = [];
        data.forEach(function (s) {
            maxArray.push(_.isEmpty(s.values) ? 0 : Math.abs(_.max(s.values, function (v) {
                return Math.abs(v.y);
            }).y));
        });
        return _.isEmpty(maxArray) ? 0 : data[0].dimension == 'ENERGY' ? _.reduce(maxArray, function (v1, v2) {
            return v1 + v2;
        }) : _.max(maxArray);
    }
    static formatDecimal(value) {
        return value % 1 == 0 ? value : Utils.getCurrentD3Locale().numberFormat(',.1f')(value);
    }
    static decompress(data) {
        for (let d of data) {
            for (var i = 1; i < d.values.length; i++) {
                d.values[i].time += d.values[i - 1].time;
                d.values[i].value += d.values[i - 1].value;
                d.values[i].reactiveReading += d.values[i - 1].reactiveReading;
                d.values[i].reading += d.values[i - 1].reading;
                d.values[i].cost += d.values[i - 1].cost;
                d.values[i].selfConsumptionReward += d.values[i - 1].selfConsumptionReward;
            }
        }
    }
    static getActiveDayIndex(data, activeDay) {
    }
    static isWeekend(d) {
        return d.getDay() == 0 || d.getDay() == 6;
    }
    static isSunday(d) {
        return d.getDay() == 0;
    }
    static getLastValue(data) {
        let result;
        if (data != null && data.values.length > 0) {
            let value = data.values[data.values.length - 1].y / (data.dimension == "ENERGY" ? Math.pow(10, 10) : Math.pow(10, 3));
            let reactiveValue = data.values[data.values.length - 1].reactiveReading / (data.dimension == "ENERGY" ? Math.pow(10, 10) : Math.pow(10, 3));
            let color = data.color;
            let time = data.values[data.values.length - 1].x;
            result = {
                value: data.dimension == 'POWER' ? Math.round(value) : value,
                reactiveValue: reactiveValue,
                color: color,
                time: time,
                meterSerialNumber: data.meterSerialNumber
            };
        }
        return result;
    }
    static getAggregation(scope, solution) {
        var interval = "";
        switch (scope) {
            case Scope.DAY:
                interval = solution === 'MARKET_PRICES' ? 'HOURLY' : "DAILY";
                //interval = "DAILY";
                break;
            case Scope.MONTH:
                interval = "MONTHLY";
                break;
            case Scope.YEAR:
                interval = "YEARLY";
                break;
        }
        return interval;
    }
    static getMomentScope(scope) {
        let startOf = scope == Scope.DAY ? 'day' : scope == Scope.MONTH ? 'month' : 'year';
        return startOf;
    }
    static getMomentIncrement(increment) {
        let momnetIncrement = increment == Scope.DAY ? 'days' : increment == Scope.MONTH ? 'months' : 'years';
        return momnetIncrement;
    }
    static getMicrogridData(data, tariff, feedPrice, pvConvertedToMicrogrid, index) {
        if (!data || data.length < 2) {
            return null;
        }
        let totalSeries = _.find(data, function (s) {
            return s.seriesType == 'CONSUMPTION_COLLECTIVE';
        });
        let producerSeries = _.find(data, function (s) {
            return s.seriesType == 'CONSUMPTION_FROM_PRODUCER';
        });
        if (totalSeries && producerSeries) {
            let unitMap = Utils.unitMap.get(totalSeries.dimension);
            const totalConsumption = Math.round(totalSeries.values[index ? index : totalSeries.values.length - 1].y);
            const consumptionFromProducer = Math.round(producerSeries.values[index ? index : producerSeries.values.length - 1].y);
            let consumptionFromGrid = totalConsumption - consumptionFromProducer;
            consumptionFromGrid = consumptionFromGrid < 0 ? 0 : consumptionFromGrid;
            let totalValue = totalConsumption;
            let netCostPerkWh;
            let consumptionFromProducerPercentage = Math.min(totalValue > 0 ? Math.round(consumptionFromProducer / totalValue * 100) : 0, 100);
            let consumptionFromGridPercentage = Math.min(totalValue > 0 ? Math.round(consumptionFromGrid / totalValue * 100) : 0, 100);
            if (consumptionFromGridPercentage + consumptionFromProducerPercentage > 100) {
                consumptionFromGridPercentage = 100 - consumptionFromProducerPercentage;
            }
            if (totalConsumption <= 0) {
                netCostPerkWh = 0;
            }
            else if (tariff && tariff.pricePerKWh !== null) {
                //TODO if the producer tariff and grid tariff is equal then make sure netCostPerKwh stays the same all the time. TEST METER: 60327984
                netCostPerkWh = (pvConvertedToMicrogrid ? consumptionFromProducerPercentage * feedPrice : (producerSeries.values[index ? index : producerSeries.values.length - 1].cost * 100))
                    + (totalSeries.values[index ? index : totalSeries.values.length - 1].cost * 100);
            }
            return {
                producerKey: producerSeries.key,
                producerColor: producerSeries.color,
                producerValue: consumptionFromProducer * unitMap.factor,
                producerPercentage: consumptionFromProducerPercentage,
                gridKey: totalSeries.key,
                gridColor: totalSeries.color,
                gridValue: consumptionFromGrid * unitMap.factor,
                gridPercentage: consumptionFromGridPercentage,
                netPrice: netCostPerkWh,
                netPriceColor: Utils.getLivePriceColor(consumptionFromProducerPercentage)
            };
        }
    }
    static getLivePriceColor(producerPercentage) {
        var rangeInterval = Math.ceil(Math.abs(100 / Utils.MICROGRID_GRADIENT_COLORS.length));
        return Utils.MICROGRID_GRADIENT_COLORS[(producerPercentage / rangeInterval) | 0];
    }
    static convertPVSeriesToMicroGrid(data, producerSource, feedPrice) {
        let result = [];
        //checks if the passed data is already a microgrid series
        let consumptionSeries = _.find(data, function (s) {
            return s.seriesType == 'CONSUMPTION_COLLECTIVE';
        });
        if (!consumptionSeries) {
            consumptionSeries = _.find(data, function (s) {
                return s.seriesType == 'CONSUMPTION';
            });
        }
        //checks if the passed data is already a microgrid series
        let productionSeries = _.find(data, function (s) {
            return s.seriesType == 'CONSUMPTION_FROM_PRODUCER';
        });
        if (!productionSeries) {
            productionSeries = _.find(data, function (s) {
                return s.seriesType == 'OWN_CONSUMPTION';
            });
        }
        if (consumptionSeries && productionSeries) {
            for (let value of productionSeries.values) {
                value.cost = (value.y / Math.pow(10, 10)) * feedPrice;
            }
            consumptionSeries.seriesType = 'CONSUMPTION_COLLECTIVE';
            consumptionSeries.key = 'Netz';
            consumptionSeries.color = '#cc232a';
            productionSeries.seriesType = 'CONSUMPTION_FROM_PRODUCER';
            productionSeries.key = producerSource;
            productionSeries.color = '#33a357';
            result.push(consumptionSeries);
            result.push(productionSeries);
        }
        return result;
    }
    static getCalculatedColor(startColor, endColor, colorCoeff) {
        var minR = tinycolor(startColor).toRgb().r;
        var minG = tinycolor(startColor).toRgb().g;
        var minB = tinycolor(startColor).toRgb().b;
        var maxR = tinycolor(endColor).toRgb().r;
        var maxG = tinycolor(endColor).toRgb().g;
        var maxB = tinycolor(endColor).toRgb().b;
        var rDiff = maxR - minR;
        var gDiff = maxG - minG;
        var bDiff = maxB - minB;
        var r = (minR + (rDiff * colorCoeff));
        var g = (minG + (gDiff * colorCoeff));
        var b = (minB + (bDiff * colorCoeff));
        r = Utils.adjustToLimit(r);
        g = Utils.adjustToLimit(g);
        b = Utils.adjustToLimit(b);
        return tinycolor({ r: r, g: g, b: b }).toHexString();
    }
    static adjustToLimit(color) {
        return color > 255 ? 255 : (color < 0 ? 0 : color);
    }
    static fillColorValues(livePrices) {
        const maxPrice = d3.max(livePrices, (d) => {
            return d.value;
        });
        const minPrice = d3.min(livePrices, (d) => {
            return d.value;
        });
        const gc = Utils.MICROGRID_GRADIENT_COLORS.slice().reverse();
        const gcLine = ["#2d924e", "#6ca449", "#9caa37", "#ccc023", "#d5a42c", "#c06327", "#b71f25"];
        const interval = (maxPrice - minPrice) / gc.length;
        for (let i = 0; i < livePrices.length; i++) {
            let marketPriceValue = livePrices[i].value;
            if (marketPriceValue) {
                let index = ((marketPriceValue - minPrice) / interval) | 0;
                index = index == gc.length ? index - 1 : index;
                let startColor = gc[index];
                let startLineColor = gcLine[index];
                if (index == gc.length - 1) {
                    livePrices[i].color = startColor;
                    livePrices[i].lineColor = startLineColor;
                }
                else {
                    let endColor = gc[index + 1];
                    let endLineColor = gcLine[index + 1];
                    let colorCoeff = (marketPriceValue - (minPrice + (index * interval))) / interval;
                    livePrices[i].color = Utils.getCalculatedColor(startColor, endColor, colorCoeff);
                    livePrices[i].lineColor = Utils.getCalculatedColor(startLineColor, endLineColor, colorCoeff);
                }
            }
        }
        return livePrices;
    }
    static parseWeatherData(data, weatherDescriptions) {
        let weather = new Array();
        for (let i = 0; i < data.length; i++) {
            if (!data[i]) {
                continue;
            }
            let date = new Date(data[i].time);
            if (!weather[date.getDate() - 1]) {
                weather[date.getDate() - 1] = new Array();
            }
            weather[date.getDate() - 1].push({
                hour: date.getHours(),
                temperatureHigh: data[i].temperatureHigh,
                temperatureLow: data[i].temperatureLow,
                icon: data[i].iconCharacter,
                description: data[i].description in weatherDescriptions ? weatherDescriptions[data[i].description] : "",
                color: data[i].description in Utils.WEATHER_ICON_COLORS ? Utils.WEATHER_ICON_COLORS[data[i].description] : "#000"
            });
        }
        return weather;
    }
    static getDeviceIconClass(seriesType) {
        let iconClass;
        if (seriesType.indexOf('BIDGELY') == -1) {
            iconClass = 'disco-' + (seriesType.replace('CONSUMPTION_DEVICE_', '')).toLowerCase();
        }
        else {
            iconClass = 'disco-' + (seriesType.replace('CONSUMPTION_DEVICE_BIDGELY_', '')).toLowerCase();
        }
        return iconClass;
    }
    static toCamelCase(str) {
        if (str == null) {
            return "";
        }
        return str.toLowerCase().replace(/\_+(.)/g, function (match, chr) {
            return chr.toUpperCase();
        });
    }
    ;
    static getCurrentD3Locale() {
        let language = i18next.language;
        return language == null || language === "de" ? this.GERMAN_LOCALE : language === "pt" ? this.PORTUGUESE_LOCALE : this.ENGLISH_LOCALE;
    }
    static getTranslatedMicrogridKey(key) {
        switch (key) {
            case 'Sonne':
                return i18next.t('solar');
            case 'Wasserkraft':
                return i18next.t('water');
            case 'Wind':
                return i18next.t('wind');
            case 'Photovoltaik':
                return i18next.t('photovoltaics');
            case 'BHWK':
                return i18next.t('cogenerationUnit');
            case 'Netz':
                return i18next.t('fromGrid');
            case 'Direktstrom':
                return i18next.t('direct');
            default:
                return i18next.t(key);
        }
    }
}
//public static readonly SERVER: string = "https://my.discovergy.com";
//public static readonly SERVER: string = "http://localhost:8080";
Utils.SERVER = '';
Utils.MICROGRID_GRADIENT_COLORS = ['#cc232a', '#d66f2c', '#edb731', '#fff12c', '#c3d545', '#54b46a', '#008739'];
Utils.unitMap = new buckets.Dictionary();
Utils.iconMap = new buckets.Dictionary();
var Scope;
(function (Scope) {
    Scope[Scope["FIFTEENMINUTE"] = 'fifteenminute'] = "FIFTEENMINUTE";
    Scope[Scope["DAY"] = 'day'] = "DAY";
    Scope[Scope["MONTH"] = 'month'] = "MONTH";
    Scope[Scope["YEAR"] = 'year'] = "YEAR";
})(Scope || (Scope = {}));
;
;
;
;
;
;
Utils.initialize();
export { Utils, Scope };
