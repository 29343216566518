"use strict";
import { Chart, Dial } from './chart';
import { Scope, Utils } from './utils';
import { Solution } from './configuration';
export default class DiscovergyBarChart extends Chart {
    constructor(chartElement, configuration) {
        super(chartElement, configuration);
        this.scope = Scope.DAY;
        this.hourlyWeatherDataCache = new buckets.Dictionary();
        this.dailyWeatherDataCache = new buckets.Dictionary();
        this.wrapCssClass = '.nv-barsWrap';
        this.updateActiveMeasurementType(this.activeMeasurementType);
        this.dataSource = configuration.contextChartDataSource;
        let chart = nv.models.extendedMultiBarChart();
        chart.stacked(true).showControls(false).reduceXTicks(false).duration(0);
        chart.useInteractiveGuideline(true);
        chart.x(function (d) {
            //return new Date(d.x);
            return moment(d.x).startOf('day').toDate();
        });
        chart.interactiveLayer.tooltip.headerFormatter(function (d) {
            return moment(d).startOf('minute').format('D.\u202FMMMM YYYY');
        });
        let that = this;
        chart.xAxis.tickFormat(function (d) {
            var tickFormat = "%H:%M";
            switch (that.scope) {
                case Scope.DAY:
                    tickFormat = "%e.";
                    break;
                case Scope.MONTH:
                    tickFormat = "%B";
                    break;
                case Scope.YEAR:
                    tickFormat = "%Y";
                    break;
            }
            return that.locale.timeFormat(tickFormat)(d);
        });
        chart.interactiveLayer.tooltip.contentGenerator(function (d) {
            //console.log("mouse on:" + moment(d.value).toDate());
            let upperBound = that.configuration.solution === "LOAD_PROFILE" ? that.configuration.lastLoadMeasurementTime : that.configuration.upperBound;
            if (moment(d.value).isAfter(moment(upperBound).endOf('day'))) {
                return '<div></div>';
            }
            var toolTipEntries = that.mapToTooltipData(d);
            toolTipEntries = _.filter(toolTipEntries, function (entry) {
                return entry.length > 0;
            });
            var table = d3.select(document.createElement("table"));
            //TODO remove extra borders in the tooltip
            table.attr('class', 'table table-condensed').style('border-bottom', 'solid 1px #ddd');
            var thead = table.append('thead');
            var tbody = table.append('tbody');
            var headerColSpan = _.max(toolTipEntries, function (e) {
                return e.length;
            }).length;
            const dateFormat = that.scope === Scope.DAY ? '%x' : that.scope === Scope.MONTH ? '%B %Y' :
                that.scope === Scope.YEAR ? '%Y' : '%x %H:%M';
            const time = moment(d.series[0].data.x);
            let tooltipHeading = '<h3>' + that.locale.timeFormat(dateFormat)(time.startOf('minute').toDate());
            if (that.configuration.solution === 'LOAD_PROFILE' && that.scope === Scope.FIFTEENMINUTE) {
                tooltipHeading += ' - ';
                if (time.hours() === 23 && time.minutes() === 45) {
                    tooltipHeading += '24:00';
                }
                else {
                    tooltipHeading += that.locale.timeFormat('%H:%M')(time.clone().add(15, 'minutes').toDate());
                }
            }
            tooltipHeading = tooltipHeading + '</h3>';
            thead.append('tr').append('td').attr('colspan', headerColSpan).append('strong')
                .classed('x-value', true).html(tooltipHeading);
            var rows = tbody.selectAll('tr')
                .data(toolTipEntries)
                .enter()
                .append('tr');
            var cells = rows.selectAll('td').data(function (row) {
                return row;
            })
                .enter()
                .append('td')
                .style('border-bottom', function (d, i) {
                if (i > 0) {
                    return "1px solid #ddd";
                }
                return "";
            })
                .style('border-right', function (d, i) {
                return "1px solid #ddd";
            })
                .style('padding', function (d, i) {
                return that.configuration.solution === 'DISAGGREGATION' ? (i % 2 == 0 ? '10px' : '12px 5px') : '5px';
            })
                .style('text-align', function (d, i) {
                return that.configuration.solution === 'DISAGGREGATION' ? (i % 2 == 0 ? 'center' : 'left') : 'left';
            })
                .html(function (v) {
                return v;
            });
            var maxLength = _.max(toolTipEntries, function (row) {
                return row.length;
            }).length;
            if (that.configuration.solution !== 'DISAGGREGATION') {
                $(tbody.node()).children('tr').each(function () {
                    if ($(this).children('td').length < maxLength) {
                        $($(this).children('td')[0]).attr('colspan', maxLength);
                        $($(this).children('td')[0]).attr('align', 'center');
                    }
                });
            }
            if (d.series[0].data.isComputedValue) {
                return `<div> ${table.node().outerHTML} <span style="color:#A9A9A9;padding-left: 5px;padding-top: 5px;font-size: x-small"> ${i18next.t("substituteValue")} </span> </div>`;
            }
            return table.node().outerHTML;
        });
        this.chart = chart;
        super.onInit();
    }
    updateScopeLabel() {
        d3.select(this.chartElement + " " + this.wrapCssClass + " .scopeLabel").remove();
        d3.select(this.chartElement + " " + this.wrapCssClass).append('text').attr("text-anchor", "start").attr("class", "scopeLabel").attr('x', 38).attr('y', 24);
        var formattedText;
        switch (this.scope) {
            case Scope.FIFTEENMINUTE:
                formattedText = this.locale.timeFormat("%x")(this.chart.xAxis.domain()[0]);
                break;
            case Scope.DAY:
                formattedText = this.locale.timeFormat("%B %Y")(this.chart.xAxis.domain()[0]);
                break;
            case Scope.MONTH:
                formattedText = this.locale.timeFormat("%Y")(this.chart.xAxis.domain()[0]);
                break;
            case Scope.YEAR:
                formattedText = i18next.t('year');
                break;
        }
        d3.select(this.chartElement + " " + this.wrapCssClass + " .scopeLabel").text(formattedText);
    }
    //TODO find a better way
    getWidth() {
        let width = $(window).width() - 100 + '';
        let w;
        try {
            w = $(this.chartElement + ' .nv-y .tick.zero')[0].getBoundingClientRect().width;
        }
        catch (exception) {
        }
        if (w != undefined) {
            width = w;
        }
        return parseInt(width);
    }
    onClickElement(callBack) {
        this.onElementCallback = callBack;
    }
    mapToTooltipData(d) {
        var reading_key = i18next.t("meterReading");
        var tooltipEntries = [[], [], [], [], [], [], [], [], [], [], [], []];
        let allData = new buckets.Dictionary();
        var basePrice;
        let solution = this.configuration.solution;
        let tariff = this.configuration.tariff;
        let locale = this.locale;
        let scope = this.scope;
        let that = this;
        if (solution === 'MARKET_PRICES' && that.scope == Scope.DAY) {
            let prices = Utils.eexPrices[new Date(d.value).getDate() - 1];
            d.series.forEach(function (s, i) {
                const color = prices && prices.length >= i ? prices[i].color : s.color;
                let entry = '<b>' + locale.timeFormat('%H:%M')(new Date(s.data.x)) + '</b> : ' + '<span style=color:' + color + '>' + that.getLocalizedSemanticValue(Math.abs(s.data.y)) + '</span>';
                if (prices && prices.length >= i) {
                    let marketPriceValue = prices[i].value;
                    if (marketPriceValue) {
                        entry = entry + ' | ' + (Math.abs(s.data.y) * Math.pow(10, -10) * marketPriceValue).toFixed(3) + ' ct';
                    }
                }
                tooltipEntries[i % 8].push(entry);
            });
            return tooltipEntries;
        }
        if (solution === 'DISAGGREGATION') {
            tooltipEntries = [];
            //d.series.reverse();
            let nonZeroSeries = _.filter(d.series, function (s) {
                return s.data.y > 0;
            });
            nonZeroSeries.reverse();
            let totalConsumption = _.reduce(nonZeroSeries, function (memo, s) {
                return s.data.y + memo;
            }, 0);
            nonZeroSeries.forEach(function (s, i) {
                tooltipEntries.push([]);
                let iconClass = 'tooltipIcon ' + Utils.getDeviceIconClass(s.seriesType);
                tooltipEntries[i].push('<i style=color:#FFFFFF;background-color:' + s.color + ' class="' + iconClass + '"></i>');
                tooltipEntries[i].push(that.getLocalizedSemanticValue(Math.abs(s.data.y)));
                if (totalConsumption !== 0) {
                    let percentage = s.data.y * 100 / totalConsumption;
                    percentage = percentage < 10 ? Math.round(percentage * 10) / 10 : Math.round(percentage);
                    tooltipEntries[i].push(percentage + '%');
                }
                if (tariff && tariff.monthlyBasePrice) {
                    tooltipEntries[i].push(Utils.formatRounded(Math.abs(s.data.cost), '€', locale, 2));
                }
            });
            return tooltipEntries;
        }
        if (solution === 'THREE_PHASE') {
            let keys = ['PHASE1', 'PHASE2', 'PHASE3', 'UNKNOWN_PHASE'];
            for (var i = 0; i < keys.length; i++) {
                var phaseIn = _.find(d.series, function (s) {
                    return s.seriesType === 'CONSUMPTION_' + keys[i];
                });
                var phaseOut = _.find(d.series, function (s) {
                    return s.seriesType === 'CONSUMPTION_' + keys[i] + "_OUT";
                });
                var phaseInEnergy = phaseIn ? phaseIn.data.y : 0;
                var phaseOutEnergy = phaseOut ? phaseOut.data.y : 0;
                var energy = that.getLocalizedSemanticValue(phaseInEnergy + phaseOutEnergy);
                if (phaseIn && phaseOut) {
                    tooltipEntries[i].push('<strong style=\"color:' + phaseIn.color + '\"><strong>' + i18next.t(Utils.toCamelCase(phaseIn.seriesType)) + '</strong>: ' + energy +
                        ' (' + that.getLocalizedSemanticValue(phaseInEnergy) + ' - ' + that.getLocalizedSemanticValue(Math.abs(phaseOutEnergy)) + ')</span>');
                }
                else if (phaseIn || phaseOut) {
                    let color = phaseIn ? phaseIn.color : phaseOut.color;
                    let key = phaseIn ? phaseIn.key : phaseOut.key;
                    tooltipEntries[i].push('<strong style=\"color:' + color + '\"><strong>' + key + '</strong>: ' + energy + '</span>');
                }
            }
            return tooltipEntries;
        }
        d.series.forEach(function (s) {
            let unitMap = Utils.unitMap.get(s.dimension);
            var key = s.data.key;
            var energy = Math.abs(s.data.y);
            var reading = Math.abs(s.data.reading);
            var cost = Math.abs(s.data.cost);
            var selfConsumptionReward = Utils.getRounded(Math.abs(s.data.selfConsumptionReward));
            var color = s.color;
            var costKey = s.costKey !== null ? i18next.t([s.costKey, s.costKey]) : '';
            var totalCost;
            var showCost = false;
            if (tariff && tariff.monthlyBasePrice) {
                basePrice = Utils.getRounded(Utils.getBasePrice(tariff, scope, moment(s.data.x)));
                totalCost = basePrice + cost;
                showCost = true;
            }
            if (that.configuration.feedInTariff) {
                showCost = true;
            }
            allData.set(s.seriesType, {
                reading: reading,
                energy: energy,
                cost: cost,
                selfConsumptionReward: selfConsumptionReward,
                color: color,
                key: i18next.t([Utils.toCamelCase(s.seriesType), s.key]),
                costKey: costKey,
                seriesType: s.seriesType,
                unitMap: unitMap,
                showCost: showCost
            });
            var rowOneEntry = '<strong style=color:' + color + '>' + key + ': </strong>' + that.getLocalizedSemanticValue(energy);
            var rowTwoEntry = '<strong style=color:' + color + '>' + reading_key + ': </strong>' + that.getLocalizedSemanticValue(reading, 0);
            tooltipEntries[0].push('<span  style=color:' + color + '>' + rowOneEntry + '</span>');
            tooltipEntries[1].push('<span style=color:' + color + '>' + rowTwoEntry + '</span>');
            var rowThreeEntry;
            if (s.seriesType == 'CONSUMPTION' && tariff && tariff.monthlyBasePrice) {
                rowThreeEntry = '<strong style=color:' + color + '>' + costKey + ': </strong>' + Utils.getStyledSumOfCosts(basePrice, locale, {
                    cost: cost,
                    color: color
                });
            }
            else if (s.seriesType == 'CONSUMPTION' && tariff == null) {
                rowThreeEntry = '';
            }
            else if (s.seriesType == 'FEED' && solution === 'PRODUCTION_CONSUMPTION') {
                rowThreeEntry = '<strong style=color:' + color + '>' + costKey + ': </strong>' +
                    Utils.getStyledSumOfCosts(0, locale, { cost: cost, color: color }, { cost: selfConsumptionReward, color: color, symbol: '€* ' }) +
                    '<br><span> * ' + i18next.t('selfConsumptionReward') + ' </span>';
            }
            else {
                rowThreeEntry = '<strong style=color:' + color + '>' + costKey + ': </strong>' + Utils.formatRounded(cost, '€', locale, 2);
            }
            if (rowThreeEntry && showCost) {
                tooltipEntries[2].push('<span style=color:' + color + '>' + rowThreeEntry + '</span>');
            }
        });
        if (solution === "LOAD_PROFILE") {
            tooltipEntries[1] = [];
            tooltipEntries[2] = [];
            return tooltipEntries;
        }
        if (solution == 'MICROGRID_CONSUMER' && allData.size() == 2) {
            var consumptionFromGrid = allData.get('CONSUMPTION_COLLECTIVE');
            var consumptionFromProducers = allData.get('CONSUMPTION_FROM_PRODUCER');
            //clears array
            tooltipEntries = [[], [], [], []];
            var rowOneEntry = '<span style=color:' + consumptionFromProducers.color + '>' + '<strong>' + Utils.getTranslatedMicrogridKey(consumptionFromProducers.key) + ': </strong>' + that.getLocalizedSemanticValue(consumptionFromProducers.energy) + '</span>' + '<br>' +
                '<span style=color:' + consumptionFromGrid.color + '>' + '<strong>' + Utils.getTranslatedMicrogridKey(consumptionFromGrid.key) + ': </strong>' + that.getLocalizedSemanticValue(consumptionFromGrid.energy) + '</span>';
            var rowTwoEntry = '<strong>' + reading_key + ': </strong>' + that.getLocalizedSemanticValue(consumptionFromGrid.reading, 0);
            //  var prop = 'entry' + i;
            tooltipEntries[0].push(rowOneEntry);
            tooltipEntries[1].push('<span style=color:' + consumptionFromGrid.color + '>' + rowTwoEntry + '</span>');
            if ((that.configuration.originalSolution == Solution.MICROGRID_CONSUMER || that.configuration.feedInTariff) && that.configuration.tariff) {
                var priceText = '<span><strong> ' + i18next.t('cost') + ' </strong>' + Utils.getStyledSumOfCosts(basePrice, locale, consumptionFromGrid, consumptionFromProducers) + '</span>';
                if (that.configuration.originalSolution == Solution.MICROGRID_CONSUMER && consumptionFromProducers.energy > 0) {
                    priceText = priceText + '<br> <span style=\'color:' + consumptionFromProducers.color + '\' >&nbsp;' + i18next.t('includingEEGAndVAT') + '</span>';
                }
                tooltipEntries[2].push(priceText);
            }
        }
        else if (solution === 'PRODUCTION_CONSUMPTION' && allData.size() == 3) {
            var consumption = allData.get('CONSUMPTION');
            var selfConsumption = allData.get('OWN_CONSUMPTION');
            var feed = allData.get('FEED');
            var totalProduction = selfConsumption.energy + feed.energy;
            var selfConsumptionQuota = 0;
            if (totalProduction > 0) {
                selfConsumptionQuota = selfConsumption.energy / totalProduction;
            }
            tooltipEntries[3].push('<span><strong> ' + i18next.t('selfConsumptionRate') + ': </strong>' + Math.round(selfConsumptionQuota * 100) + "%</span>");
            tooltipEntries[3].push(' ');
            let selfSufficiency = selfConsumption.energy / (selfConsumption.energy + consumption.energy);
            tooltipEntries[3].push('<span><strong> ' + i18next.t('selfSufficiency') + ': </strong>' + Math.round(selfSufficiency * 100) + "%</span>");
            /*to show the tooltip in desired order*/
            tooltipEntries.forEach(function (entries) {
                entries.reverse();
            });
        }
        else if (allData.size() > 1 && that.configuration.tariff !== null && that.configuration.solution !== 'BIDIRECTIONAL') {
            tooltipEntries[2] = [];
            tooltipEntries[2].push('<span><strong> ' + i18next.t('cost') + ': </strong>' + Utils.getStyledSumOfCosts(basePrice, locale, ...allData.values()) + '</span>');
        }
        return tooltipEntries;
    }
    highlightBars(from, to) {
        let that = this;
        let lowerBound = moment(from).clone().startOf('day');
        //to-1 is to limit the higilighting to current period when the upper bound is starting timestamp of the period
        let upperBound = moment(to - 1).clone().endOf('day');
        d3.selectAll(this.chartElement + " .nv-groups .nv-group").selectAll("rect").classed("active", function (d, i) {
            if (that.scope !== Scope.DAY || !d)
                return false;
            let dayStart = moment(d.x).clone().startOf('day');
            return dayStart.isSameOrAfter(lowerBound) && dayStart.isSameOrBefore(upperBound);
        });
    }
    update(from, to, interval) {
        let parameters = new buckets.Dictionary();
        parameters.set("from", from + "");
        parameters.set("to", to + "");
        parameters.set("aggregation", interval);
        let that = this;
        this.updateChart(this.dataSource, parameters);
    }
    updateScope(scopeIncrement) {
        //this.updateTickFormat();
        let lowerBound = moment(this.configuration.lowerBound);
        let upperBound = moment(this.configuration.upperBound);
        let scope = this.scope == Scope.DAY ? Scope.MONTH : Scope.YEAR;
        var from = moment(this.chart.xAxis.domain()[0]);
        if (scopeIncrement == 0) {
            if (this.activeDay) {
                from = moment(this.activeDay);
            }
        }
        if (from.isBefore(lowerBound)) {
            from = lowerBound.clone();
        }
        var newFrom;
        var newTo;
        if (this.scope == Scope.YEAR) {
            newFrom = moment(new Date(2008, 0));
            newTo = moment(new Date(new Date().getFullYear() + 1, 0));
        }
        else {
            newFrom = from.add(scopeIncrement, Utils.getMomentIncrement(scope)).startOf(Utils.getMomentScope(scope));
            newTo = from.clone().add(1, Utils.getMomentIncrement(scope)).startOf(Utils.getMomentScope(scope));
        }
        console.log(this.scope + "," + newFrom.toDate() + " - " + newTo.toDate());
        if (newFrom.isAfter(moment(this.configuration.upperBound)) || newTo.isBefore(moment(this.configuration.lowerBound))) {
            return;
        }
        newFrom = newFrom.valueOf();
        newTo = newTo.valueOf();
        this.update(newFrom, newTo, Utils.getAggregation(this.scope, this.configuration.solution));
    }
    onNavigateChart(dial) {
        switch (dial) {
            case Dial.UP:
                if (this.scope != Scope.DAY) {
                    this.scope = this.scope == Scope.YEAR ? Scope.MONTH : Scope.DAY;
                    this.updateScope(0);
                }
                break;
            case Dial.DOWN:
                if (this.scope != Scope.YEAR) {
                    this.scope = this.scope == Scope.DAY ? Scope.MONTH : Scope.YEAR;
                    this.updateScope(0);
                }
                break;
            case Dial.LEFT:
                this.updateScope(-1);
                break;
            case Dial.RIGHT:
                this.updateScope(1);
                break;
        }
    }
    preprocess(data) {
        Utils.decompress(data);
    }
    onBeforeRender() {
        if (this.configuration.solution === 'DISAGGREGATION' || this.configuration.solution === 'MARKET_PRICES') {
            this.chart.showLegend(false);
        }
        else {
            this.chart.showLegend(true);
        }
        let that = this;
        var prefix = d3.formatPrefix(this.seriesAbsoluteMax);
        if (this.seriesAbsoluteMax > 10000000 && (this.activeMeasurementType == 'ELECTRICITY' || this.activeMeasurementType === 'HEATING')) {
            this.chart.yAxis.axisLabel(prefix.symbol + this.baseUnit).tickFormat(function (d) {
                return Utils.formatDecimal(prefix.scale(d * that.conversionFactor));
            });
        }
        else {
            this.chart.yAxis.axisLabel(((this.activeMeasurementType == 'ELECTRICITY' || this.activeMeasurementType === 'HEATING') ? 'k' : '') + this.baseUnit).tickFormat(function (d) {
                return Utils.formatDecimal((d * that.conversionFactor) * ((that.activeMeasurementType == 'ELECTRICITY' || that.activeMeasurementType === 'HEATING') ? Math.pow(10, -3) : 1));
            });
        }
        if (this.configuration.solution === 'THREE_PHASE') {
            this.chart.legend.enableDoubleClick(false);
        }
        else {
            this.chart.legend.enableDoubleClick(true);
        }
        //adds extra series to look at market prices a day ahead when available
        if (this.configuration.solution == 'MARKET_PRICES' && moment(that.from).isSameOrAfter(moment().startOf('month'))
            && !moment().isSame(moment().endOf('month'), 'day') && that.scope == Scope.DAY) {
            for (var h = 0; h < 24; h++) {
                that.data[h].values[moment().get('date')].y = _.reduce(that.data[h].values, function (memo, val) {
                    return memo + val.y;
                }, 0) / moment().get('date') - 1;
            }
        }
    }
    onAfterRender() {
        d3.select(".nv-barsWrap").classed('nv-context', true);
        if (this.activeDay) {
            this.highlightBars(moment(this.activeDay).clone().startOf('day').valueOf(), moment(this.activeDay).clone().endOf('day').valueOf());
        }
        if (this.configuration.solution === 'PRODUCTION_CONSUMPTION' || this.configuration.solution === 'MICROGRID_CONSUMER') {
            d3.select(this.chartElement + ' .nv-legendWrap')
                .datum(_.sortBy(this.data, function (s) {
                return s.sortIndex;
            }))
                .call(this.chart.legend);
        }
        if (this.configuration.solution === 'MARKET_PRICES' && this.scope == Scope.DAY) {
            this.addMarketPricesColors();
        }
        d3.selectAll(this.chartElement + " .weatherData").remove();
        this.addWeatherData();
        //to hide negative series in legend
        if (this.configuration.solution === 'THREE_PHASE') {
            let filteredData = _.filter(this.data, function (s) {
                return s.seriesType.indexOf('OUT') == -1 && s.seriesType.indexOf('UNKNOWN') == -1;
            });
            d3.select(this.chartElement + ' .nv-legendWrap')
                .datum(filteredData)
                .call(this.chart.legend);
        }
    }
    onInitialize() {
        let that = this;
        d3.select(this.chartElement).selectAll('.nv-x .nv-wrap.nv-axis g g text').style("fill", function (d, i) {
            return (Utils.isWeekend(d) && that.scope == Scope.DAY) ? "#f00" : "#000";
        }).style("font-weight", function (d, i) {
            return (Utils.isSunday(d) && that.scope == Scope.DAY) ? "bold" : "normal";
        });
        d3.select(this.chartElement).selectAll('.nv-x .nv-wrap.nv-axis g g text').on("click", function (d, i) {
            that.focusOnElement(d.getTime());
        });
        /* let xAxis = d3.select(this.chartElement + " .nv-x");
        // xAxis.style("display", "none");
         d3.select(this.chartElement).append(function () {
           return xAxis.node();
         }).attr("transform", "translate(60, 284)").attr("class","nvd3 nv-wrap nv-x nv-wrap nv-axis");*/
        //d3.select("#chart2 .nv-x").attr('transform',"translate(60, 284)");
        this.chart.multibar.dispatch.on('elementClick', function (e) {
            that.focusOnElement(e.data.x);
        });
    }
    focusOnElement(time) {
        if (this.scope == Scope.FIFTEENMINUTE) {
            return;
        }
        let e = {};
        let startOf = Utils.getMomentScope(this.scope);
        let incrementAmount = Utils.getMomentIncrement(this.scope);
        var from = moment(time).startOf(startOf);
        let to = from.clone().add(1, incrementAmount).startOf(startOf);
        //let to = from.clone().endOf(startOf);
        e.scope = this.scope;
        e.from = from.valueOf();
        e.to = to.valueOf();
        var upperBound = this.configuration.upperBound;
        if (this.scope == Scope.DAY && this.configuration.solution === 'MARKET_PRICES' && moment(upperBound).isSameOrAfter(moment().startOf('month'))) {
            upperBound = moment().startOf('day').valueOf() + 86400000;
        }
        if (e.from > (this.configuration.solution === 'LOAD_PROFILE' ? this.configuration.lastLoadMeasurementTime : upperBound)) {
            return;
        }
        if (this.scope == Scope.DAY) {
            this.activeDay = from.valueOf();
        }
        this.scope = this.scope == Scope.YEAR ? Scope.MONTH : Scope.DAY;
        if (this.onElementCallback) {
            this.onElementCallback(e);
        }
    }
    getLocalizedSemanticValue(value, precision) {
        const prefix = d3.formatPrefix(this.seriesAbsoluteMax);
        let scaledValue;
        if (this.activeMeasurementType == 'ELECTRICITY' || this.activeMeasurementType === 'HEATING') {
            if (this.seriesAbsoluteMax > 10000000) {
                scaledValue = prefix.scale(value * this.conversionFactor);
                return this.locale.numberFormat(",." + precision + 'f')(precision ? scaledValue : Utils.getRounded(scaledValue)) + ' ' + prefix.symbol + this.baseUnit;
            }
            scaledValue = value * this.conversionFactor * Math.pow(10, -3);
            return this.locale.numberFormat(",." + precision + 'f')(precision ? scaledValue : Utils.getRounded(scaledValue)) + ' kWh';
        }
        scaledValue = value * this.conversionFactor;
        return this.locale.numberFormat(",." + precision + 'f')(precision ? scaledValue : Utils.getRounded(scaledValue)) + ' ' + this.baseUnit;
    }
    updateActiveMeasurementType(m) {
        this.activeMeasurementType = m;
        let unitMap = Utils.unitMap.get((m === 'ELECTRICITY' || m === 'HEATING') ? 'ENERGY' : 'VOLUME');
        this.baseUnit = unitMap.unit;
        this.conversionFactor = unitMap.factor;
    }
    addMarketPricesColors() {
        let that = this;
        //console.info("fetching market prices:" + new Date(pState.from.getTime()) + " - " + new Date(pState.to.getTime()));
        Utils.getJSONAsync("/json/PortalJSON.getMarketPrices?from=" + this.from + "&to=" + this.to, function (dataIn) {
            let incomingPrice = dataIn;
            const marketPrices = new Array();
            for (let i = 0; i < incomingPrice.length; i++) {
                const priceEntry = incomingPrice[i];
                const date = new Date(priceEntry.time);
                if (!marketPrices[date.getDate() - 1])
                    marketPrices[date.getDate() - 1] = new Array();
                marketPrices[date.getDate() - 1].push({
                    time: priceEntry.time,
                    hour: date.getHours(),
                    value: priceEntry.pricePerKWh
                });
            }
            if (that.scope === Scope.DAY) {
                for (let i = 0; i < marketPrices.length; i++) {
                    Utils.fillColorValues(marketPrices[i]);
                }
                d3.selectAll('.nv-barsWrap .nv-groups .nv-group').selectAll('rect').style('fill', function (d, i) {
                    const marketPrice = marketPrices[i];
                    return (marketPrice && marketPrice[d.series]) ? moment(d.x).isAfter(moment()) ? '#bdc3c7' : marketPrice[d.series].color : moment(d.x).isAfter(moment()) ? '#FFFFFF' : '#FF0000';
                });
            }
            Utils.eexPrices = marketPrices;
        });
    }
    addWeatherData() {
        if (!this.configuration.isWeatherApiSupportedForTheLocation) {
            return;
        }
        let that = this;
        let from = this.from;
        if (this.scope == Scope.DAY) {
            var data = this.dailyWeatherDataCache.get(moment(this.from).startOf('day').valueOf());
            if (data == null) {
                Utils.getJSONAsync(this.configuration.constructUrl("getWeatherData") + "&from=" + from + "&to=" + this.to + "&scope=DAILY", dataIn => {
                    let data = dataIn;
                    that.dailyWeatherDataCache.set(moment(from).startOf('day').valueOf(), data);
                    if (that.from <= from && that.to >= from) {
                        that.addDailyWeatherData(data);
                    }
                });
            }
            else {
                this.addDailyWeatherData(data);
            }
        }
        else if (this.scope == Scope.FIFTEENMINUTE) {
            var data = this.hourlyWeatherDataCache.get(moment(this.from).startOf('day').valueOf());
            if (data == null) {
                Utils.getJSONAsync("/json/PortalJSON.getWeatherData?containerId=" + this.configuration.containerId + "&from=" + from + "&to=" + this.to + "&scope=HOURLY", dataIn => {
                    let data = dataIn;
                    that.hourlyWeatherDataCache.set(moment(from).startOf('day').valueOf(), data);
                    if (that.from <= from && that.to >= from) {
                        that.addHourlyWeatherData(data);
                    }
                });
            }
            else {
                this.addHourlyWeatherData(data);
            }
        }
    }
    addHourlyWeatherData(data) {
        let weather = Utils.parseWeatherData(data, this.weatherDescriptions);
        for (let i = 0; i < data.length; i++) {
            if (Math.abs((new Date()).getTime() - data[i].time) < 60 * 60 * 1000) {
                $('#currentWeatherIcon').text(data[i].iconCharacter);
                $('#currentWeatherIcon').attr('title', data[i].description in this.weatherDescriptions ? 'aktuell ' + this.weatherDescriptions[data[i].description] : "");
                $('#currentWeatherText').text(data[i].temperatureHigh.toFixed() + '\u202f°C');
                break;
            }
        }
        d3.selectAll(this.chartElement + " .nv-x .tick").each(function () {
            const date = d3.select(this).data()[0];
            let weatherDatum;
            if (weather[date.getDate() - 1]) {
                for (let entry of weather[date.getDate() - 1]) {
                    if (entry.hour === date.getHours()) {
                        weatherDatum = entry;
                    }
                }
            }
            if (!weatherDatum) {
                return;
            }
            d3.select(this)
                .append('text')
                .attr('class', 'weatherData')
                .attr('dy', '2em')
                .attr('style', "text-anchor: middle; font-family: 'discovergy-portal'; font-size: 1.25em; fill: " + weatherDatum.color)
                .html(function (d) {
                return "<title>" + weatherDatum.description + "</title>" + weatherDatum.icon;
            });
            d3.select(this)
                .append('text')
                .attr('class', 'weatherData')
                .attr('dy', '4em')
                .attr('style', "text-anchor: middle;")
                .text(function (d) {
                return weatherDatum.temperatureHigh.toFixed() + '\u202f°C';
            });
        });
    }
    addDailyWeatherData(data) {
        let weather = Utils.parseWeatherData(data, this.weatherDescriptions);
        d3.selectAll(this.chartElement + " .nv-x .tick").each(function () {
            const date = d3.select(this).data()[0];
            let weatherDatum;
            if (weather[date.getDate() - 1]) {
                weatherDatum = weather[date.getDate() - 1][0];
            }
            if (!weatherDatum) {
                return;
            }
            d3.select(this)
                .append('text')
                .attr('class', 'weatherData')
                .attr('dy', '2em')
                .attr('style', "text-anchor: middle; font-family: 'discovergy-portal'; font-size: 1.25em; fill: " + weatherDatum.color)
                .html(function (d) {
                return "<title>" + weatherDatum.description + "</title>" + weatherDatum.icon;
            });
            d3.select(this)
                .append('text')
                .attr('class', 'weatherData')
                .attr('dy', '4em')
                .attr('style', "text-anchor: middle;")
                .text(function (d) {
                return weatherDatum.temperatureLow.toFixed() + '\u202f/\u202f' + weatherDatum.temperatureHigh.toFixed();
            });
        });
        $('#weatherunit').remove();
        $('#chartContainer').after('<div id="weatherunit" style="text-align: right;padding-right: 30px;padding-bottom: 10px;margin-top:-17px;font-size: 90%;color: #000;">°C</div>');
        $('#weatherterms').remove();
        $('#weatherunit').after('<div id="weatherterms" style="text-align: right;padding-right: 10px;padding-bottom: 10px;font-size: 90%;color: #aaa;">Weather data powered by <a href="https://darksky.net/poweredby/" rel="nofollow" target="_blank">Dark Sky</a></div>');
    }
}
